import { Appointment, CalendarEvent } from './types';

const getFieldValue = (
  fields: Record<string, any>,
  possibleNames: string[]
): string => {
  for (const name of possibleNames) {
    if (fields[name] !== undefined) {
      return fields[name];
    }
  }
  return '';
};

export const convertAppointmentsToEvents = (
  appointmentGridResponse: string
): CalendarEvent[] => {
  try {
    const parsed = JSON.parse(appointmentGridResponse) as {
      appointments: Record<string, Record<string, Appointment[]>>;
    };
    if (!parsed.appointments) return [];

    const events: CalendarEvent[] = [];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(parsed.appointments).forEach(([date, userAppointments]) => {
      Object.entries(userAppointments).forEach(([userId, appointments]) => {
        (appointments as Appointment[]).forEach((appointment) => {
          const displayFields = appointment.displayFields || {};

          // Get customer name
          const firstName = getFieldValue(displayFields, [
            'First Name',
            'FirstName',
          ]);
          const lastName = getFieldValue(displayFields, [
            'Last Name',
            'LastName',
          ]);
          const customerName =
            firstName && lastName ? `${firstName} ${lastName}` : 'Untitled';

          // Get address components
          const address = getFieldValue(displayFields, ['Address']);
          const city = getFieldValue(displayFields, ['City']);
          const state = getFieldValue(displayFields, ['State']);
          const zipCode = getFieldValue(displayFields, [
            'Zip',
            'Zip Code',
            'ZipCode',
            'Zipcode',
          ]);

          // Get phone and notes
          const phoneNumber = getFieldValue(displayFields, [
            'Phone Number',
            'PhoneNumber',
            'Phone',
          ]);
          const notes = getFieldValue(displayFields, [
            'Notes',
            'Appointment Notes',
            'AppointmentNotes',
          ]);

          // Create full address
          const fullAddress = [address, city, state, zipCode]
            .filter(Boolean)
            .join(', ');

          // Create dates using the same format as the grid
          const formattedDate = appointment.appointmentDate.replace(/-/g, '/');

          // Create start date
          const startHours = Math.floor(appointment.appointmentStartTime / 60);
          const startMinutes = appointment.appointmentStartTime % 60;
          const startTimeStr = `${startHours
            .toString()
            .padStart(2, '0')}:${startMinutes.toString().padStart(2, '0')}`;
          const startDate = new Date(`${formattedDate} ${startTimeStr}`);

          // Create end date
          const endHours = Math.floor(appointment.appointmentEndTime / 60);
          const endMinutes = appointment.appointmentEndTime % 60;
          const endTimeStr = `${endHours
            .toString()
            .padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}`;
          const endDate = new Date(`${formattedDate} ${endTimeStr}`);

          const event: CalendarEvent = {
            event_id: appointment.appointmentId,
            id: appointment.appointmentId,
            title: customerName,
            start: startDate,
            end: endDate,
            admin_id: userId,
            dealId: appointment.dealId,
            extendedProps: {
              displayFields: {
                ...appointment.displayFields,
                customerName,
                fullAddress,
                phoneNumber,
                notes,
                firstName,
                lastName,
                address,
                city,
                state,
                zipCode,
              },
            },
          };

          events.push(event);
        });
      });
    });

    // Sort events by start time
    events.sort((a, b) => a.start.getTime() - b.start.getTime());

    return events;
  } catch (error) {
    console.error('Error converting appointments to events:', error);
    return [];
  }
};
