import { API, graphqlOperation } from 'aws-amplify';
import { useQuery } from '@tanstack/react-query';

interface TeamMember {
  id: string;
  isActive: boolean;
  status: string;
  user: {
    id: string;
    name: string;
    status: string;
    imageName: string;
    imageType: string;
    functionalRoles: string[];
  };
}

interface Team {
  id: string;
  name: string;
  imageName: string;
  imageType: string;
  users: {
    items: TeamMember[];
  };
}

const fetchTeams = async () => {
  const myQuery = `
    query myQuery($orgID: ID!) {
      searchCircles(
        filter: {
          orgID: { eq: $orgID }
          isDeleted: { eq: false }
        }
        limit: 999
        sort: { direction: asc, field: name }
      ) {
        items {
          id
          name
          imageName
          imageType
          users {
            items {
              id
              isActive
              status
              user {
                id
                name
                status
                imageName
                imageType
                functionalRoles
              }
            }
          }
        }
      }
    }
  `;

  const res = await API.graphql(
    graphqlOperation(myQuery, {
      orgID: (global as any).me.orgID,
    })
  );

  // @ts-ignore
  const teams = res.data?.searchCircles?.items || [];
  return teams as Team[];
};

export const useTeamsQuery = () => {
  return useQuery({
    queryKey: ['teams'],
    queryFn: fetchTeams,
  });
};
