import {
  Box,
  Button,
  CircularProgress,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import React, { useEffect, useState /*, useCallback */ } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
// import Moment from 'moment';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../../graphql/mutations';
import gql from 'graphql-tag';
import {
  getLocalDateTime,
  newLocalToISODateFormat,
  // getCurrentUTCDate,
} from '../../../common/Utilities';
import FlexFields from './FlexFields';
import { BackIcon, GamifyIcon } from '../../../components/GamifyIcon';
import { Formik } from 'formik';
import { GamifyToast } from '../../../common/CustomToasts';
import DealFormPageSkeleton from './DealFormPageSkeleton';
// import CancelDealFormModal from './CancelDealFormModal';
import DynamicConfirmModal from '../../../common/DynamicConfirmModal';
import {
  selectDealTypeId,
  selectDealTypesConfigMap,
} from '../../../store/reducers/deals';
import './DealFormPage.css';
import { usePermissions } from '../../../context/PermissionsContext/PermissionsContext';
import { PermissionKeys } from '../../../context/PermissionsContext/permissionTypes';

export default function DealFormPage(props) {
  // NOTE: Only needed when DealFormPage is used at /edit/<dealID>, but it is now used in a modal and can use props
  const location = useLocation();

  const reduxDealTypeId = useSelector(selectDealTypeId);
  const reduxDealTypesConfigMap = useSelector(selectDealTypesConfigMap);

  // NOTE: DealFormModal is used now, so dealId is passed in as a prob and is not in useParams now
  const { dealId } = useParams();

  const [isOnKanban, setIsOnKanban] = useState(false);
  const [setter, setSetter] = useState(null);
  const [closer, setCloser] = useState(null);

  const { checkPermission } = usePermissions();

  const canCreate = checkPermission(PermissionKeys.Sales_CreateDeal);

  // NOTE: If deal form is ever successfully converted to a modal, use this:
  const isModal = false;
  let onTriggerDealsRefetch, onTriggerSelectedDealRefetch, setShowDealFormModal;
  // const {
  //   dealId,
  //   dealType,
  //   dealTypesCategoryIdMap,
  //   dealTypesConfigMap,
  //   setShowDealFormModal,
  //   onTriggerDealsRefetch,
  //   onTriggerSelectedDealRefetch,
  //   isModal,
  // } = props;
  // console.log('DealFormPage dealId: ', dealId);

  // NOTE: navigate is only used when DealFormPage is used at a separate /edit/<dealID> route, not if it's a modal
  const navigate = useNavigate();

  useEffect(() => {
    if (dealId) {
      // Edit Form
      // Setter and closer only populate when routed through navigate... if there is a dealId and neither are populated then the user manually navigated to this URL and should be routed out as they most likely don't have permission... even if they do have permission they should still be rerouted when manually going to the edit URL
      if (!setter && !closer) {
        // NOTE: As there is not way to determine if they manually route from the kanban or list w/ isOnKanban as that comes from navigating within the app, the user will always reroute to the default board
        navigate('/pipeline/board', { replace: true });
      }
    } else {
      // Create Form
      if (!canCreate) {
        // NOTE: As there is not way to determine if they manually route from the kanban or list w/ isOnKanban as that comes from navigating within the app, the user will always reroute to the default board
        navigate('/pipeline/board', { replace: true });
      }
    }
  }, [navigate]);

  let dealID = '';

  // TO DO: add current owner id as current user id if lambda doesn't set it.
  const newDeal = {
    id: '',
    name: '',
    description: '',
    value: '',
    imageName: '',
    imageType: '',
    status: 'active',
    orgID: global.me.orgID,
    role: '',
    contactID: '',
    contactType: '',
    categoryID: '',
    notes: '',
    followupDate: '',
    contacts: {
      items: [],
    },
  };

  const [checkedLocation, setCheckedLocation] = useState(false);
  const [defaultDispositionID, setDefaultDispositionID] = useState(null);
  const [validateFields, setValidateFields] = useState(false);
  const [canSave, setCanSave] = useState(true);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [initialValues, setInitialValues] = useState(newDeal);
  const [savedDealID, setSavedDealID] = useState('');
  const [isFormDataLoading, setIsFormDataLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);

  // NOTE: GG-1577 required working around maintainDealV3 not returning the deal id due to errors, so now this needs to be used here to avoid lint errors from web hooks
  if (savedDealID === 'not-empty') {
    console.log(savedDealID);
  }

  const handleCancel = () => {
    setCancelOpen(true);
  };

  // check location state for dealTypes maps and if routed from kanban or list, get existing deal info if editing
  useEffect(() => {
    setCheckedLocation(true);

    // TO DO: move commented out props below into the location.state of DealsList handleNewDeal/handleEditDeal
    // const { dealID, config, isPinDrop, address, categoryID } = props;

    // // TO GET DEALID DEPENDING ON WHAT DATA IS PROVIDED
    // const dealIdToFetch =
    // dealID || (isPinDrop && !address.isInitialDrop ? address.id : "");

    // NOTE: The following code using location is only needed if DealFormPage is used at a separate /edit/<dealID> route, but a modal is used now so it is replaced by props
    if (!location.state) return;

    if (location.state?.isOnKanban) {
      setIsOnKanban(true);
    }

    if (location.state?.setter) {
      setSetter(location.state.setter);
    }

    if (location.state?.closer) {
      setCloser(location.state.closer);
    }

    // NOTE: If DealFormPage is used at a separate /edit/<dealID> route, dealType and dealTypesCategoryIdMap need to be args of this function and the invocation of it below needs tempDealType and tempDealTypesCategoryIdMap from above passed into it
    async function prepareFormData() {
      setIsFormDataLoading(true);
      // NOTE: dealTypeId is the categoryID
      if (dealId) {
        await fetchDeal(dealId, reduxDealTypeId, props.dispositionID);
      }

      setIsFormDataLoading(false);
    }

    prepareFormData();
  }, [dealId]);

  // ///////////
  //
  //  get deal
  //
  // ///////////

  async function fetchDeal(
    dealID,
    categoryID,
    // flexFields,
    // address,
    // notes,
    dispositionID
  ) {
    // console.log('fetchDeal dealID: ', dealID);
    const ourCategoryID = categoryID;

    if (
      (dispositionID === '' ||
        dispositionID === undefined ||
        dispositionID === null) &&
      categoryID !== null
    ) {
      setDefaultDispositionID(await getDefaultDispositionID(ourCategoryID));
    } else {
      setDefaultDispositionID(props.dispositionID);
    }

    // TO DO: add back in address if map gets implemented on web
    // const ourFlexAttributes = {};
    // if (dealID === '') {
    //   //
    //   //  Set the address in the flexFields
    //   //
    //   console.log('----address: ', address);
    //   // if ( address !== undefined && address != "" && address != null) {
    //   //   ourFlexAttributes['Zip'] = address['postalCode']
    //   //   ourFlexAttributes['City'] = address['city']
    //   //   ourFlexAttributes['State'] = address['region']
    //   //   ourFlexAttributes['Address'] = address['address']
    //   // }

    //   if (address !== undefined && address !== '' && address !== null) {
    //     for (const ff of flexFields) {
    //       console.log('!!!ff: ', ff);
    //       if ('pinMappingField' in ff) {
    //         if (ff.pinMappingField in address) {
    //           ourFlexAttributes[ff.name] = address[ff.pinMappingField];
    //         }
    //       }
    //       let ourAddress = '';
    //       if ('addressMapping' in ff) {
    //         for (const af of ff.addressMapping) {
    //           console.log('af: ', af);
    //           if (ourAddress === '') {
    //             ourAddress = address[af];
    //           } else {
    //             ourAddress = ourAddress + ', ' + address[af];
    //           }
    //         }
    //         console.log('ourAddress: ', ourAddress);
    //         ourFlexAttributes[ff.name] = ourAddress;
    //       }
    //     }
    //   }
    //   console.log('ourFlexAttributes: ', ourFlexAttributes);
    //   const ourDeal = {
    //     id: '',
    //     name: '',
    //     description: '',
    //     value: '',
    //     imageName: '',
    //     imageType: '',
    //     status: 'active',
    //     orgID: global.me.orgID,
    //     role: '',
    //     contactID: '',
    //     categoryID: '',
    //     contactType: '',
    //     notes: notes,
    //     followupDate: '',
    //     currentOwnerID: global.me.id,
    //     contacts: {
    //       items: [],
    //     },
    //   };

    //   ourDeal.categoryID = ourCategoryID;
    //   ourDeal.flexAttributes = JSON.stringify(ourFlexAttributes);
    //   console.log('ourDeal: ', ourDeal);
    //   setInitialValues(ourDeal);
    //   setIsLoaded(true);
    //   return ourDeal;
    // }

    const myQuery = gql`
      query MyQuery($dealID: ID!) {
        getDeal(id: $dealID) {
          id
          name
          notes
          status
          imageName
          description
          flexAttributes
          categoryID
          value
          currentDispositionID
          currentOwnerID
          pinAddress {
            name
            address
            city
            region
            postalCode
          }
        }
      }
    `;

    // console.log('!!getDeal: ', dealID);
    const getDealRet = await API.graphql({
      query: myQuery,
      variables: { dealID },
    });
    // console.log('!!!getDealRet: ', getDealRet);
    if (!getDealRet.data.getDeal) {
      setInitialValues(newDeal);
      return newDeal;
    }
    const deal = getDealRet.data.getDeal;
    const flexFieldDataJSON = JSON.parse(deal.flexAttributes);
    // console.log('!!!deal: ', deal);

    // let imageList = []
    // const dealImageLists = deal.imageName === ''?[]:JSON.parse(deal.imageName);
    // if (dealImageLists.length > 0) {
    //   for (const image of dealImageLists) {
    //     imageList = [...imageList, image]
    //   }
    // }

    const ourDeal = {
      id: deal.id,
      // name: deal.name,
      // description: deal.description,
      notes: deal.notes,
      // value: deal.value.toString(),
      imageName: deal.imageName,
      imageType: deal.imageType,
      status: deal.status,
      orgID: deal.orgID,
      role: '',
      contactID: '',
      contacts: deal.contacts,
      flexFieldData: deal.flexAttributes !== '' ? flexFieldDataJSON : '',
      pinAddress: deal.pinAddress,
      categoryID: deal.categoryID,
      // currentDispositionID: props.dispositionID,
      currentDispositionID: deal.currentDispositionID
        ? deal.currentDispositionID
        : defaultDispositionID,
      followupDate: '',
      currentOwnerID: deal.currentOwnerID,
    };

    // console.log('^^^^ourDeal: ', ourDeal);
    setInitialValues(ourDeal);
    // setIsLoaded(true);
  }

  // ///////////
  //
  //  update deal
  //
  // ///////////

  async function updateMyDeal(ourDeal) {
    // console.log('updateMyDeal ourDeal: ', ourDeal);

    try {
      const newDeal = await API.graphql(
        graphqlOperation(mutations.maintainDealV3, {
          dealInfo: JSON.stringify(ourDeal),
        })
      );
      // console.log('----newDeal: ', newDeal);
      // alert("Deal saved");
      setInitialValues(ourDeal);

      // props.onClose()

      const dealID = JSON.parse(newDeal.data.maintainDealV3);
      return dealID;
    } catch (err) {
      console.error('updateMyDeal(): error saving ' + ':' + err.message);
      // alert("There was an error saving your deal.");
      GamifyToast.error('There was an error saving your deal.');
      return '';
    }
  }

  // ///////////
  //
  //  Create deal
  //
  // ///////////

  async function createMyDeal(values, actions, config, categoryID, onComplete) {
    setIsLoading(true);
    // console.log('createMyDeal args: ', values, config, categoryID, onComplete);
    const flexFields = config.flexFields;

    let ourStage = values.currentStageID; // Use the currentStageID from the input
    if (values.id === '') {
      //
      //  If we're creating a new deal, set the currentStageID = the default stage
      //
      const myQuery = gql`
        query MyQuery($categoryID: ID!) {
          listStageByCategorySequence(
            categoryID: $categoryID
            filter: { isMapDefault: { eq: true } }
            limit: 10000
          ) {
            items {
              id
              sequence
            }
          }
        }
      `;
      const myQueryRes = await API.graphql({
        query: myQuery,
        variables: { categoryID },
      });
      // console.log('createMyDeal myQueryRes: ', myQueryRes);
      const stages = myQueryRes.data.listStageByCategorySequence.items;

      const sortedStages = stages.sort((a, b) => {
        if (a.sequence < b.sequence) return -1;
        if (a.sequence > b.sequence) return 1;
        return 0;
      });

      // NOTE: If the org isn't configured to have a default deal stage, there won't be a stage to get an id from. End the function and notify the user to contact support and get a default deal stage set.
      if (sortedStages?.length > 0) {
        ourStage = sortedStages[0].id;
      } else {
        setIsLoading(false);
        GamifyToast.error(
          'Your org needs to have a default deal stage set to create a deal. Please contact support to get a default deal stage set.'
        );
        return 'stage-error';
      }
    }
    let flexFieldData;
    // console.log('createMyDeal !!!values: ', values);
    if ('flexFieldData' in values) {
      // console.log ("***found flexFieldData", values.flexFieldData);
      flexFieldData = values.flexFieldData;
    } else {
      // console.log ("***found flexAttributes", values.flexAttributes);
      flexFieldData = values.flexAttributes;
    }
    if (typeof flexFieldData === 'string') {
      flexFieldData = JSON.parse(flexFieldData);
    }

    // console.log('createMyDeal BEFORE flexFieldData: ', flexFieldData);
    // console.log('createMyDeal ourStage: ', ourStage);

    const ourDeal = {
      name: '',
      // description: values.description,
      notes: values.notes,
      status: values.status,
      imageName: values.imageName,
      imageType: values.imageType,
      currentStageID: ourStage,
      currentOwnerID: values.currentOwnerID,
      currentOwnerType: 'user',
      orgID: values.orgID,
      // value: values.value,
      isDeleted: false,
      flexAttributes: JSON.stringify(flexFieldData),
      // categoryID: values.categoryID,
      categoryID: values.categoryID || categoryID,
      currentDispositionID: props.dispositionID
        ? props.dispositionID
        : defaultDispositionID,
      tmst: newLocalToISODateFormat(getLocalDateTime()),
    };
    // console.log('createMyDeal @@@ourDeal: ', ourDeal);
    for (const ff of flexFields) {
      // console.log('ff: ', ff);
      if ('createMyDeal dealField' in ff) {
        // console.log ("found dealField: '" + ff.dealField + "'");
        if (ff.dealField in ourDeal) {
          // console.log ("found dealField, name: '" +  ff.name + "'");
          //
          // Append to the value that's already there
          //
          if (ourDeal[ff.dealField].length > 0) {
            ourDeal[ff.dealField] =
              ourDeal[ff.dealField] + ' ' + flexFieldData[ff.name];
          } else {
            ourDeal[ff.dealField] = flexFieldData[ff.name];
          }
        } else {
          //
          //  New value, just create it
          //
          ourDeal[ff.dealField] = flexFieldData[ff.name];
        }
      }
    }

    // console.log(
    //   'createMyDeal config.dealMappingsName: ',
    //   config.dealMappingsName
    // );
    // console.log('createMyDeal AFTER flexFieldData: ', flexFieldData);

    if (config.dealMappingsName !== '') {
      const fields = config.dealMappingsName.split(',');
      for (const field of fields) {
        // console.log("createMyDeal field: '" + field.trim() + "'");
        // console.log("createMyDeal ourDeal.name: '" + ourDeal.name + "'");
        if (field.trim() in flexFieldData) {
          if (ourDeal.name) {
            ourDeal.name += ' ';
          }
          ourDeal.name += flexFieldData[field.trim()];
        }
      }
      // console.log('createMyDeal ourDeal.name: ', ourDeal.name);
    }
    // console.log(
    //   'createMyDeal config.dealMappingsDescription: ',
    //   config.dealMappingsDescription
    // );
    if (config.dealMappingsDescription !== '') {
      if (config.dealMappingsDescription in flexFieldData) {
        ourDeal.description = flexFieldData[config.dealMappingsDescription];
      }
    }
    // console.log(
    //   'createMyDeal config.dealMappingsValue: ',
    //   config.dealMappingsValue
    // );
    if (config.dealMappingsValue !== '') {
      if (config.dealMappingsValue in flexFieldData) {
        ourDeal.value = flexFieldData[config.dealMappingsValue];
      }
    }

    // console.log('createMyDeal !!!ourDeal: ', ourDeal);
    // console.log('createMyDeal !!!values: ', values);
    if (values.id !== '') {
      ourDeal.id = values.id;
      // console.log('updateMyDeal: ', ourDeal);
      const ret = await updateMyDeal(ourDeal);
      // console.log('---ret: ', ret);

      // NOTE: updateMyDeal's ret is "" if it fails, should stop function and loading if that happens
      if (ret === '') {
        console.error('updateMyDeal error');
        setIsLoading(false);
        return;
      }

      if (onComplete) {
        onComplete(true);
      }

      // NOTE: Need to trigger refetch of deals if on a modal because there is no reroute to trigger the deals fetch now
      if (isModal) {
        onTriggerDealsRefetch();
        onTriggerSelectedDealRefetch();
      }

      GamifyToast.success(`Deal updated successfully`);

      return ret;
    }
    // console.log ("props.addressID: ", props.addressID);

    // if (props.addressID !== "undefined") {
    //   ourDeal.id = props.addressID;
    // }

    try {
      const createDealRes = await API.graphql(
        graphqlOperation(mutations.maintainDealV3, {
          dealInfo: JSON.stringify(ourDeal),
        })
      );
      // console.log('createMyDeal createDealRes: ', createDealRes);
      dealID = JSON.parse(createDealRes.data.maintainDealV3);
    } catch (err) {
      // TO DO:
      GamifyToast.error(`There was an error saving your deal`);
      setIsLoading(false);
      console.error('createMyDeal: error creating deal: ' + err.message);
      if (onComplete) {
        onComplete(false);
      }
      return '';
    }

    initialValues.id = dealID;
    setInitialValues(initialValues);
    setSavedDealID(dealID);
    if (onComplete) {
      onComplete(true);
    }

    // NOTE: Need to trigger refetch of deals if on a modal because there is not reroute to trigger the deals fetch now
    if (isModal) {
      onTriggerDealsRefetch();
    }

    console.log('actions: ', actions);
    actions.resetForm();

    setTimeout(() => {
      // Delay to coincide with setTimeout on the createDeal navigate, need to give DB time to update
      GamifyToast.success(`Deal created successfully`);
    }, 1000);
    return dealID;
  }

  // For Formik onSubmit:
  async function doSubmit(values, actions, config, categoryID) {
    // console.log(
    //   'doSubmit args (values, actions, config, categoryID): ',
    //   values,
    //   actions,
    //   config,
    //   categoryID
    // );
    if (values.flexFieldData === undefined) {
      //
      //  flexFieldData is undefined if we haven't changed anything in the form
      //

      // NOTE: navigate is only used when DealFormPage is used at a separate /edit/<dealID> route, but a modal is used now
      // setShowDealFormModal(false);
      if (isOnKanban) {
        // NOTE: rerouted to not just the board/list until maintainDealV3 is fixed per GG-1577
        // navigate(`/pipeline/board/${savedDealID}`);

        navigate(`/pipeline/board`);
      } else {
        // NOTE: rerouted to not just the board/list until maintainDealV3 is fixed per GG-1577
        // navigate(`/pipeline/list/${savedDealID}`);

        navigate(`/pipeline/list`);
      }

      // onClose(savedDealID);
    }

    const ret = await createMyDeal(values, actions, config, categoryID, false);

    // NOTE: createMyDeal returns 'stage-error' if an error due to an org having no default stage occurs, the user is notified of this error and should stay on the form and not be rerouted on deal creation failure.
    if (ret === 'stage-error') return;

    // NOTE: Commented out for GG-1577. maintainDealV3 needs to be fixed so it returns the dealID, currently returns '' for new deals and undefined for updated deals
    if (ret !== '') {
      console.log(ret);
    }
    // if (ret !== '') {

    // NOTE: navigate is only used when DealFormPage is used at a separate /edit/<dealID> route, but a modal is used now
    // setShowDealFormModal(false);
    setTimeout(() => {
      // Lambda sometimes loads faster than DB updates, delay to give DB time to update
      if (isOnKanban) {
        setIsLoading(false);

        // NOTE: rerouted to not just the board/list until maintainDealV3 is fixed per GG-1577
        // navigate(`/pipeline/board/${ret}`);

        navigate(`/pipeline/board`);
      } else {
        setIsLoading(false);

        // NOTE: rerouted to not just the board/list until maintainDealV3 is fixed per GG-1577
        // navigate(`/pipeline/list/${ret}`);

        navigate(`/pipeline/list`);
      }
    }, 1250);

    // props.onClose(ret);
    // }

    actions.setSubmitting(false);
  }

  async function getDefaultDispositionID(categoryID) {
    // console.log('getDefaultDispositionID categoryID: ', categoryID);
    const myQuery = gql`
      query MyQuery($categoryID: ID = "") {
        listStageDispositionByCategoryID(
          categoryID: $categoryID
          filter: { isDeleted: { eq: false } }
        ) {
          items {
            id
            isDefault
            title
          }
        }
      }
    `;
    const ret = await API.graphql({
      query: myQuery,
      variables: { categoryID },
    });
    // console.log('getDefaultDisposition: ', ret);
    let _defaultDispositionID = '';
    let defaultDefined = false;
    for (const disposition of ret.data.listStageDispositionByCategoryID.items) {
      // console.log('!!!disposition: ', disposition);
      if (disposition.isDefault) {
        _defaultDispositionID = disposition.id;
        defaultDefined = true;
      }
      if (
        !defaultDefined &&
        disposition.title.toString().toLowerCase().trim() === 'interested'
      ) {
        _defaultDispositionID = disposition.id;
      }
    }
    if (_defaultDispositionID === '') {
      console.error(
        `No default disposition or interested disposition found for category: ${categoryID}.`
      );
    }
    return _defaultDispositionID;
  }

  if (!checkedLocation || isFormDataLoading) {
    // if (true) {
    // console.log('DealFormPageSkeleton checkedLocation: ', checkedLocation);
    // console.log('DealFormPageSkeleton isFormDataLoading: ', isFormDataLoading);
    return (
      <DealFormPageSkeleton
        setShowModal={setShowDealFormModal}
        isModal={isModal}
        isOnKanban={isOnKanban}
      ></DealFormPageSkeleton>
    );
  }

  // TO DO: put paramsDealId check into ternaries in JSX below to render add or edit onClicks or add/edit text
  if (reduxDealTypeId !== '') {
    return (
      // New Deal: navigated from Deals List, deal type is set from there
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validateOnMount={true} // Need this to validate before first submit attempt
        // validationSchema={createDealValidationSchema} // TO DO: Add yup validation schema here over doing manual validation functions
        onSubmit={(values, actions) =>
          doSubmit(
            values,
            actions,
            reduxDealTypesConfigMap[reduxDealTypeId],
            reduxDealTypeId
          )
        }
      >
        {(formikProps) => (
          <Box
            className={
              isModal ? 'deal-form-modal-container' : 'deal-form-page-container'
            }
          >
            {/* {console.log('formikProps: ', formikProps)} */}
            {/* <CancelDealFormModal
                props={{ cancelOpen, setCancelOpen }}
              ></CancelDealFormModal> */}
            <DynamicConfirmModal
              showDynamicConfirmModal={cancelOpen}
              setShowDynamicConfirmModal={setCancelOpen}
              zIndex={100002}
              leftAlignText={true}
              title={'Leave without saving changes?'}
              subtitle={`If you leave without saving, your progress will be lost.`}
              cancelButtonText={'Leave'}
              onCancel={() => {
                // NOTE: The following is for if DealFormPage is used at a separate /edit/<dealID> route, but a modal is used now
                // setShowDealFormModal(false);
                if (dealId) {
                  if (isOnKanban) {
                    navigate(`/pipeline/board/${dealId}`);
                  } else {
                    navigate(`/pipeline/list/${dealId}`);
                  }
                } else {
                  if (isOnKanban) {
                    navigate('/pipeline/board');
                  } else {
                    navigate('/pipeline/list');
                  }
                }
                setCancelOpen(false);
              }}
              confirmButtonText={'Keep editing'}
              onConfirm={() => {
                setCancelOpen(false);
              }}
            ></DynamicConfirmModal>
            <Box
              className={
                isModal
                  ? 'deal-form-modal-top-container'
                  : 'deal-form-page-top-container'
              }
            >
              <Box
                className={
                  isModal
                    ? 'deal-form-modal-cancel-container'
                    : 'deal-form-page-cancel-container'
                }
              >
                <Box className={'deal-form-cancel-button-container'}>
                  <Button
                    className={
                      isModal
                        ? 'deal-form-modal-cancel-button'
                        : 'deal-form-page-cancel-button'
                    }
                    onClick={() => {
                      if (formikProps.dirty) {
                        handleCancel();
                      } else {
                        // NOTE: The following is for if DealFormPage is used at a separate /edit/<dealID> route
                        // setShowDealFormModal(false);
                        if (dealId) {
                          if (isOnKanban) {
                            navigate(`/pipeline/board/${dealId}`);
                          } else {
                            navigate(`/pipeline/list/${dealId}`);
                          }
                        } else {
                          if (isOnKanban) {
                            navigate('/pipeline/board');
                          } else {
                            navigate('/pipeline/list');
                          }
                        }
                      }
                    }}
                    disableRipple={true}
                  >
                    <BackIcon></BackIcon>
                    <Typography
                      className={
                        isModal
                          ? 'deal-form-modal-cancel-button-text'
                          : 'deal-form-page-cancel-button-text'
                      }
                    >
                      Cancel
                    </Typography>
                  </Button>
                </Box>
                <Box
                  className={
                    isModal
                      ? 'deal-form-modal-title-container'
                      : 'deal-form-page-title-container'
                  }
                >
                  <Typography
                    className={'deal-form-title-text'}
                    onClick={() => {
                      if (global?.me?.isAdmin) {
                        console.log('Deal Type ID: ', reduxDealTypeId);
                      }
                    }}
                  >
                    {/* {dealId
                      ? `Edit ${dealType.toLowerCase()} deal`
                      : `New ${dealType.toLowerCase()} deal`} */}
                    {dealId ? `Edit deal` : `New deal`}
                  </Typography>
                </Box>
                <Box className={'deal-form-place-holder-container'}></Box>
              </Box>
            </Box>
            <Box
              className={
                isModal
                  ? 'modal-deal-form-container'
                  : 'page-deal-form-container'
              }
            >
              <FlexFields
                dealTypeId={reduxDealTypeId}
                dealTypesConfigMap={reduxDealTypesConfigMap}
                dealId={dealId}
                values={formikProps.values}
                validateFields={validateFields}
                // TO DO: canSave also used in onSubmit of create/edit button
                // canSave={(val) => {
                //   setCanSave(val);
                // }}
                canSave={canSave}
                setCanSave={setCanSave}
                fieldSetter={(val) => {
                  formikProps.setFieldValue('flexFieldData', val);
                }}
                hasSubmitted={hasSubmitted}
                setHasSubmitted={setHasSubmitted}
              ></FlexFields>
              <Box
                style={{
                  backgroundColor: '#F0F0F3',
                  borderRadius: 8,
                  marginTop: 15,
                  paddingBottom: 0,
                }}
              >
                <Box
                  style={{
                    width: '100%',
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    backgroundColor: '#D0D2D8',
                    padding: 16,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    style={{ fontSize: 18, color: '#222428', fontWeight: 600 }}
                  >
                    Notes
                  </Typography>
                  <GamifyIcon icon={'notes'} color="#323232" />
                </Box>
                <Box style={{ padding: '24px 16px 8px 16px' }}>
                  <TextareaAutosize
                    style={{
                      border: 'none',
                      borderRadius: 8,
                      width: '100%',
                      resize: 'none',
                      padding: '16px 12px 16px 12px',
                    }}
                    placeholder={'Add notes here'}
                    value={formikProps.values.notes}
                    onChange={(e) => {
                      formikProps.setFieldValue('notes', e.target.value);
                    }}
                  ></TextareaAutosize>
                </Box>
              </Box>
              <Box className={'save-deal-button-container'}>
                <Button
                  className={'save-deal-button'}
                  onClick={() => {
                    // console.log(
                    //   'formikProps.values.flexFieldData: ',
                    //   formikProps.values?.flexFieldData
                    // );
                    // if ( formikProps.values.flexFieldData === undefined ) {
                    //   alert ("Please enter something in this form before you press save.");
                    // } else {
                    //
                    //  Something changed, so save it
                    //
                    if (!canSave) {
                      GamifyToast.error('Please fix all errors before saving.');
                      // alert('Please fix all errors before saving.');
                    }
                    setHasSubmitted(true);
                    setValidateFields(true);
                    // const errors = readyToSave ( flexFields, formikProps.values );

                    // TO DO: comment everything below back in after putting all props into location.state
                    // if (
                    //   props.requireDate &&
                    //   !global.appSettings.features.deal
                    //     .hideFollowupDateField &&
                    //   formikProps.values.followupDate === ""
                    // ) {
                    //   alert(
                    //     "Please enter a new contact date before saving"
                    //   );
                    // } else
                    if (canSave) {
                      formikProps.handleSubmit();
                    }
                  }}
                  disableRipple={true}
                >
                  {isLoading ? (
                    <CircularProgress
                      style={{ color: 'white', height: 24, width: 24 }}
                    ></CircularProgress>
                  ) : (
                    <Typography className={'save-deal-button-text'}>
                      {/* TO DO: change text based on design */}
                      {dealId ? 'Update deal' : 'Save changes'}
                    </Typography>
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Formik>
    );
  } else {
    // TO DO: Make display to give option to create deal based off all possible deal types?
    return (
      <></>
      // <Typography className={'test-deal-form-text'}>
      //   SELECT DEAL TYPE
      // </Typography>
    );
  }
}
