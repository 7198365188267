import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  FormControlLabel,
  Switch,
  MenuItem,
  Select,
  FormControl,
  AccordionDetails,
} from '@mui/material';
import { CustomTextField } from './styledComponents';
import './CRMConfigV2.css';
import { typeSelectOptions, doorFieldDataOptions } from '../utils/tempsUtils';

const FlexFieldEditor = ({
  formName,
  field,
  fieldIndex,
  handleFieldChange,
  deleteField,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleDeleteClick = () => {
    if (confirmDelete) {
      deleteField(formName, fieldIndex);
    } else {
      setConfirmDelete(true);
      setTimeout(() => setConfirmDelete(false), 5000);
    }
  };

  function toCamelCase(str) {
    return str
      .replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase()) // Capitalize letters after non-alphanumeric characters
      .replace(/^[A-Z]/, (match) => match.toLowerCase()); // Ensure the first letter is lowercase
  }

  return (
    <AccordionDetails style={{ padding: '10px 20px' }}>
      <Box mb={2}>
        <Typography style={{ fontSize: 'medium', fontWeight: 500 }}>
          Heading
        </Typography>
        <CustomTextField
          value={field.heading}
          onChange={(e) =>
            handleFieldChange(formName, fieldIndex, 'heading', e.target.value)
          }
          fullWidth
          margin="normal"
        />
      </Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box width="48%">
          <Typography style={{ fontSize: 'medium', fontWeight: 500 }}>
            Label
          </Typography>
          <CustomTextField
            value={field.label}
            onChange={(e) =>
              handleFieldChange(formName, fieldIndex, 'label', e.target.value)
            }
            fullWidth
            margin="normal"
          />
        </Box>
        <Box width="50%">
          <Typography style={{ fontSize: 'medium', fontWeight: 500 }}>
            Field Name
          </Typography>
          <CustomTextField
            value={field.name}
            onChange={(e) => {
              const cleanedString = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
              handleFieldChange(formName, fieldIndex, 'name', cleanedString);
            }}
            fullWidth
            margin="normal"
          />
        </Box>
      </Box>
      {['String', 'Notes', 'Number', 'Money', 'Phone', 'Email'].includes(
        field.type
      ) && (
        <Box mb={2}>
          <Box>
            <Typography style={{ fontSize: 'medium', fontWeight: 500 }}>
              {`Input Placeholder (Will be default to Label if not filled in)`}
            </Typography>
            <CustomTextField
              value={field.placeholder}
              onChange={(e) =>
                handleFieldChange(
                  formName,
                  fieldIndex,
                  'placeholder',
                  e.target.value
                )
              }
              fullWidth
              margin="normal"
            />
          </Box>
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box
          width={
            ['String', 'Notes', 'Number', 'Money'].includes(field.type)
              ? '48%'
              : '100%'
          }
        >
          <Typography style={{ fontSize: 'medium', fontWeight: 500 }}>
            Type
          </Typography>
          <FormControl fullWidth margin="normal">
            <Select
              className="square-select"
              value={field.type}
              onChange={(e) =>
                handleFieldChange(formName, fieldIndex, 'type', e.target.value)
              }
            >
              {typeSelectOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  style={{
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                    borderTop: 'none',
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = 'lightgray')
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = 'white')
                  }
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {['String', 'Notes'].includes(field.type) && (
          <>
            <Box width="24%">
              <Typography style={{ fontSize: 'medium', fontWeight: 500 }}>
                Min Length
              </Typography>
              <CustomTextField
                type="number"
                value={field.minLength}
                onChange={(e) =>
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'minLength',
                    e.target.value
                  )
                }
                fullWidth
                margin="normal"
              />
            </Box>
            <Box width="24%">
              <Typography style={{ fontSize: 'medium', fontWeight: 500 }}>
                Max Length
              </Typography>
              <CustomTextField
                type="number"
                value={field.maxLength}
                onChange={(e) =>
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'maxLength',
                    e.target.value
                  )
                }
                fullWidth
                margin="normal"
              />
            </Box>
          </>
        )}
        {['Number', 'Money'].includes(field.type) && (
          <>
            <Box width="24%">
              <Typography style={{ fontSize: 'medium', fontWeight: 500 }}>
                Min Value
              </Typography>
              <CustomTextField
                type="number"
                value={field.minValue}
                onChange={(e) =>
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'minValue',
                    e.target.value
                  )
                }
                fullWidth
                margin="normal"
              />
            </Box>
            <Box width="24%">
              <Typography style={{ fontSize: 'medium', fontWeight: 500 }}>
                Max Value
              </Typography>
              <CustomTextField
                type="number"
                value={field.maxValue}
                onChange={(e) =>
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'maxValue',
                    e.target.value
                  )
                }
                fullWidth
                margin="normal"
              />
            </Box>
          </>
        )}
      </Box>
      {field.type === 'List' && (
        <Box mb={2}>
          <Typography style={{ fontSize: 'medium', fontWeight: 500 }}>
            List Items
          </Typography>
          <Box style={{ display: 'flex', width: '100%' }}>
            <Box style={{ width: '100%' }}>
              <Typography>Label</Typography>
            </Box>
            <Box style={{ width: '100%' }}>
              <Typography>{`ID (MUST BE UNIQUE)`}</Typography>
            </Box>
            <Box style={{ width: 64 }}></Box>
          </Box>
          {field.values.map((listItem, listIndex) => (
            <Box key={listIndex} display="flex" alignItems="center">
              <CustomTextField
                value={listItem.label}
                onChange={(e) => {
                  const updatedValues = [...field.values];
                  updatedValues[listIndex].label = e.target.value;
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'values',
                    updatedValues
                  );
                }}
                fullWidth
                margin="normal"
              />
              <CustomTextField
                value={listItem.id}
                onChange={(e) => {
                  // Regex fxn to remove characters that are not alphanumeric, dashes, or underscores
                  const cleanString = (str) =>
                    str.replace(/[^a-zA-Z0-9-_]/g, '');
                  const updatedValues = [...field.values];
                  updatedValues[listIndex].id = cleanString(e.target.value);
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'values',
                    updatedValues
                  );
                }}
                fullWidth
                margin="normal"
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  const updatedValues = [...field.values];
                  updatedValues.splice(listIndex, 1);
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'values',
                    updatedValues
                  );
                }}
              >
                Delete
              </Button>
            </Box>
          ))}
          <Button
            variant="contained"
            onClick={() => {
              const updatedValues = [...field.values, { label: '', id: '' }];
              handleFieldChange(formName, fieldIndex, 'values', updatedValues);
            }}
          >
            Add List Item
          </Button>
        </Box>
      )}
      {/* NOTE: Let Order Lines additional configuration inputs empty until clarification is made */}
      {field.type === 'Order Lines' && <></>}
      {field.type === 'Contact' && (
        <Box>
          <Typography
            style={{ marginBottom: 8 }}
          >{`Contact Fields:`}</Typography>
          <Box
            style={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              marginBottom: 16,
            }}
          >
            {[
              'Company',
              'First Name',
              'Last Name',
              'Email',
              'Phone Number',
              'Phone Extension',
              'Phone Number 2',
              'Phone Extension 2',
              'Address',
              'City',
              'State',
              'Postal Code',
              'Country Code',
            ].map((contactField) => {
              const contactKey = toCamelCase(contactField);

              // If show is not checked, required is disabled
              const isShowChecked =
                field.contactFields?.[contactKey]?.show || false;
              const isRequiredChecked =
                field.contactFields?.[contactKey]?.required || false;
              const isRequiredDisabled = !isShowChecked;

              return (
                <Box key={contactKey}>
                  <Typography>{`${contactField}:`}</Typography>
                  <Box style={{ display: 'flex' }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isShowChecked}
                          onChange={(e) => {
                            // Get the already existring contactFields or create a new object
                            const contactFields = field.contactFields || {};
                            // If making Show false, then required must also be set to false (this also disables required)
                            contactFields[contactKey] = e.target.checked
                              ? {
                                  ...(contactFields[contactKey] || {}),
                                  show: e.target.checked,
                                }
                              : {
                                  show: e.target.checked,
                                  required: e.target.checked,
                                };

                            handleFieldChange(
                              formName,
                              fieldIndex,
                              'contactFields',
                              contactFields
                            );
                          }}
                          color="primary"
                        />
                      }
                      label="Show"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={isRequiredDisabled}
                          checked={isRequiredChecked}
                          onChange={(e) => {
                            // Get the already existring contactFields or create a new object
                            const contactFields = field.contactFields || {};
                            contactFields[contactKey] = {
                              ...(contactFields[contactKey] || {}),
                              required: e.target.checked,
                            };

                            handleFieldChange(
                              formName,
                              fieldIndex,
                              'contactFields',
                              contactFields
                            );
                          }}
                          color="primary"
                        />
                      }
                      label="Required"
                    />
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box style={{ width: '48%', display: 'flex', flexDirection: 'column' }}>
          <FormControlLabel
            control={
              <Switch
                checked={field.required}
                onChange={(e) =>
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'required',
                    e.target.checked
                  )
                }
                color="primary"
              />
            }
            label="Required"
          />
          <FormControlLabel
            control={
              <Switch
                checked={field.hideLabel}
                onChange={(e) =>
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'hideLabel',
                    e.target.checked
                  )
                }
                color="primary"
              />
            }
            label="Hide Label on Form"
          />
          <FormControlLabel
            control={
              <Switch
                checked={field.displayField}
                onChange={(e) =>
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'displayField',
                    e.target.checked
                  )
                }
                color="primary"
              />
            }
            label="Display Field"
          />
          {/* <FormControlLabel
            control={
              <Switch
                checked={field.pickerDisplayField}
                onChange={(e) =>
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'pickerDisplayField',
                    e.target.checked
                  )
                }
                color="primary"
              />
            }
            label="Display in Dropdown List"
          /> */}
        </Box>
        <Box width="50%">
          <Typography style={{ fontSize: 'medium', fontWeight: 600 }}>
            Door Field Data
          </Typography>
          <FormControl fullWidth margin="normal">
            <Select
              className="square-select"
              value={field.pinMappingField}
              onChange={(e) =>
                handleFieldChange(
                  formName,
                  fieldIndex,
                  'pinMappingField',
                  e.target.value
                )
              }
            >
              {doorFieldDataOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  style={{
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                    borderTop: 'none',
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = 'lightgray')
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = 'white')
                  }
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          variant="contained"
          color={confirmDelete ? 'error' : 'secondary'}
          onClick={handleDeleteClick}
          style={{ marginLeft: 'auto' }}
        >
          {confirmDelete ? 'Confirm Delete' : 'Delete Field'}
        </Button>
      </Box>
    </AccordionDetails>
  );
};

export default FlexFieldEditor;
