import React, { useState, useEffect, useMemo } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';

import {
  Box,
  Button,
  IconButton,
  Table as MuiTable,
  TableBody,
  TableContainer,
  TableHead,
  Avatar,
  Checkbox,
  InputAdornment,
  Typography,
  CircularProgress,
} from '@mui/material';
import { MoreHoriz, Search /* , ControlPoint */ } from '@mui/icons-material';
// import EditUserModal from './EditUserModal';
// import DeleteUserModal from './DeleteUserModal';
import DynamicConfirmModal from '../../common/DynamicConfirmModal';
import { API } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import gql from 'graphql-tag';
import moment from 'moment';
import Fuse from 'fuse.js';
import getUserAvatar from '../../common/utils/getUserAvatar';
import { GamifyToast } from '../../common/CustomToasts';
import getSentenceInitials from '../../common/utils/getSentenceInitials';
import {
  UsersTableCell,
  UsersTableRow,
  TitleTableCell,
  // CustomMenu,
  // CustomMenuItem,
  // ResultsText,
} from './TableComponents';
import {
  SearchInput,
  // AddNewButton,
  NavigationButton,
  // StyledSelect,
  // StyledMenuItem,
} from './StyledInputs';
import {
  CustomMenu,
  CustomMenuItem,
  StyledSelect,
  StyledMenuItem,
} from '../../common/StyledComponents';
// import InviteUserModal from './InviteUserModal';
import { useNavigate } from 'react-router';
// import BetaIcon from '../../common/BetaIcon';
import { GamifyIcon } from '../../components/GamifyIcon';
import UserFormModal from './UserFormModal';
// import { gridColumnsTotalWidthSelector } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadUsersAction,
  // addUserAction,
  // removeUserAction,
  updateUserAction,
  updateProfileAction,
  removeUsersAction,
  removeProfilesAction,
  setSearchStringAction,
  setFiltersAction,
  setCurrentPageAction,
  setTotalPagesAction,
  selectAllUsers,
  selectAllProfiles,
  selectSearchString,
  selectFilters,
  selectCurrentPage,
  selectTotalPages,
  selectUsersPerPage,
  setUsersPerPageAction,
} from '../../store/reducers/users';
import { usePermissions } from '../../context/PermissionsContext/PermissionsContext';
import { PermissionKeys } from '../../context/PermissionsContext/permissionTypes';

function capitalizeFirstLetter(text) {
  if (!text) {
    return;
  }

  return text.charAt(0)?.toUpperCase() + text.slice(1);
}

export default function UsersTable() {
  const { checkPermission, hasPermission, orgRoles } = usePermissions();

  // console.log('listFilters orgRoles: ', orgRoles);

  const normalizedOrgRoles = orgRoles.reduce((acc, role) => {
    acc[role.id] = role;
    return acc;
  }, {});

  // Get users slice of state from redux:
  const reduxUsers = useSelector(selectAllUsers);
  const reduxProfiles = useSelector(selectAllProfiles);
  const reduxSearchString = useSelector(selectSearchString);
  const reduxFilters = useSelector(selectFilters);
  const reduxCurrentPage = useSelector(selectCurrentPage);
  const reduxTotalPages = useSelector(selectTotalPages);
  const reduxUsersPerPage = useSelector(selectUsersPerPage);

  // NOTE: reduxUsers is a normalized object, convert back to an array and sort by name
  const reduxUsersArr = Object.values(reduxUsers);
  reduxUsersArr.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  // console.log(
  //   'redux: ',
  //   reduxSearchString,
  //   reduxFilters,
  //   reduxCurrentPage,
  //   reduxTotalPages,
  //   reduxUsersPerPage
  // );
  // console.log('reduxUsersArr: ', reduxUsersArr);

  const [selectedUser, setSelectedUser] = useState(null);

  const [users, setUsers] = useState(reduxUsersArr.length ? reduxUsersArr : []);
  const [searchData, setSearchData] = useState(users);
  const options = {
    keys: ['name', 'email', 'title', 'phoneNumber'],
    includeScore: true,
    threshold: 0.2,
  };

  const [triggerFetchUsers, setTriggerFetchUsers] = useState(0);
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [searchString, setSearchString] = useState(reduxSearchString || '');
  // const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [totalPages, setTotalPages] = useState(reduxTotalPages || 1);
  const [currentPage, setCurrentPage] = useState(reduxCurrentPage || 1);
  const [listFilters, setListFilters] = useState(
    reduxFilters || { role: 'all', status: 'all' }
  );

  // console.log('listFilters: ', listFilters);

  const [filteredList, setFilteredList] = useState([]);
  const [limit, setLimit] = useState(reduxUsersPerPage || 50);
  const [showNewUserFormModal, setShowNewUserFormModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // NOTE: removed searchString from useEffect dependency array as Fuse is used for searching rather than doing a new query each search
  useEffect(() => {
    async function searchUsers() {
      if (isLoading) return;
      setIsLoading(true);
      if (users.length > 0) {
        setUsers([]); // reset users when at least 1
      }
      setTimeout(async () => {
        const [tempUsers, tempTotalPages] = await fetchPeople(searchString);
        // console.log('tempUser: ', tempUsers);
        // console.log('tempTotalPages: ', tempTotalPages);
        dispatch(loadUsersAction(tempUsers));
        dispatch(setTotalPagesAction(tempTotalPages));
        setIsLoading(false);
      }, 750); // adjust if more time needed for users to delete before refetch...
    }

    if (reduxUsersArr.length === 0) {
      searchUsers();
    }
  }, [triggerFetchUsers /* , searchString */]);

  // Need to update users state every time the redux store for users is updated
  useEffect(() => {
    setUsers(reduxUsersArr);
  }, [reduxUsers]);

  const onTriggerUsersRefetch = () => {
    setTriggerFetchUsers(triggerFetchUsers + 1);
  };

  const fuse = new Fuse(users, options);

  useEffect(() => {
    // NOTE: This is how search was successfully done in AddMemberLinkTeamModal
    const searchedData = fuse.search(searchString);
    //   console.log(
    //     'SearchTeams onChange searchedData: ',
    //     searchedData
    //   );

    const formattedData = searchedData.map((data) => ({
      ...data,
      ...data.item,
    }));

    setSearchData(formattedData);
    // Update total pages based on formattedData length if searchString has length
    if (searchString.length > 0)
      setTotalPages(Math.ceil(formattedData.length / limit));

    // Update redux state as well
    dispatch(setTotalPagesAction(Math.ceil(formattedData.length / limit)));
  }, [searchString]);

  // console.log('searchString and searchData: ', searchString, searchData);
  // console.log('users: ', users);

  useEffect(() => {
    if (!users) return;
    if (listFilters.role + listFilters.status === 'allall') {
      setFilteredList(null);
      setTotalPages(Math.ceil(users.length / limit));

      // Update redux state as well
      dispatch(setTotalPagesAction(Math.ceil(users.length / limit)));
    }
    // Use searchData when there's a searchString
    let list = searchString.length > 0 ? searchData : users;

    if (listFilters.role !== 'all') {
      list = list.filter(
        (user) => user.orgs.items[0].roleID === listFilters.role
      );
    }
    if (listFilters.status !== 'all') {
      list = list.filter((user) => user.status === listFilters.status);
    }
    setTotalPages(Math.ceil(list.length / limit));
    setFilteredList(list);

    // Update redux state as well
    dispatch(setTotalPagesAction(Math.ceil(list.length / limit)));
  }, [listFilters, users, limit]);

  const fetchPeople = async (searchString, loadMore) => {
    // console.log('fetchPeople searchString: ', searchString);
    setIsLoading(true);
    const ourSearchString = searchString + '*';

    try {
      const searchUsersQuery = gql`
        query MyQuery($orgID: ID!, $searchString: String!, $nextToken: String) {
          searchUsers(
            filter: {
              name: { wildcard: $searchString }
              orgID: { eq: $orgID }
              status: { ne: "deleted" }
            }
            limit: 750
            sort: { direction: asc, field: name }
            nextToken: $nextToken
          ) {
            items {
              id
              extID
              birthdate
              email
              imageName
              imageType
              initials
              location
              name
              functionalRoles
              phoneNumber
              pronoun
              role
              status
              username
              title
              avatarID
              levelID
              createdAt
              orgs {
                items {
                  id
                  roleID
                  status
                  memberType
                  orgID
                }
              }
            }
            nextToken
            total
          }
        }
      `;
      let usersList = [];
      let usersNextToken = null;
      do {
        const searchResponse = await API.graphql({
          query: searchUsersQuery,
          variables: {
            orgID: global.me.orgID,
            searchString: ourSearchString || '*',
            nextToken: usersNextToken,
          },
        });

        usersList = [
          ...usersList,
          ...(searchResponse?.data?.searchUsers?.items || []),
        ];
        usersNextToken = searchResponse?.data?.searchUsers?.nextToken;
      } while (usersNextToken);

      const usersData = usersList;
      const totalPages = Math.ceil(usersData.total / limit);
      setTotalPages(totalPages);

      if (usersData?.length) {
        const usersWithAvatar = await Promise.all(
          usersData.map(async (original) => {
            const avatar = await getUserAvatar(
              original?.imageName,
              original?.imageType,
              true
            );
            const initials =
              original.initials || getSentenceInitials(original?.name);

            return {
              ...original,
              avatar,
              initials,
            };
          })
        );

        if (loadMore) {
          setUsers([...users, ...usersWithAvatar]);
        } else {
          setUsers(usersWithAvatar);
        }
        setIsLoading(false);

        // return for updating redux state
        if (loadMore) {
          return [[...users, ...usersWithAvatar], totalPages];
        } else {
          return [usersWithAvatar, totalPages];
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error('ERROR FETCHING PEOPLE', error);
    }
  };

  const handleUserCheck = (event, user) => {
    if (event.target.checked) {
      setCheckedUsers((prevCheckedUsers) => [...prevCheckedUsers, user]);
    } else {
      setCheckedUsers((prevCheckedUsers) =>
        prevCheckedUsers.filter((checkedUser) => checkedUser.id !== user.id)
      );
    }
  };

  const handleFilterChange = (e) => {
    // console.log('listFilter e.target.name: ', e.target.name);
    // console.log('listFilter e.target.value: ', e.target.value);
    setListFilters((p) => ({ ...p, [e.target.name]: e.target.value }));
    // Move user back to page 1
    setCurrentPage(1);

    // Update redux state as well
    dispatch(
      setFiltersAction({ ...reduxFilters, [e.target.name]: e.target.value })
    );
    dispatch(setCurrentPageAction(1));
  };

  const roleSortingFn = (rowA, rowB, columnId) => {
    // NOTE: coumnId isn't used but the linter won't allow commits if a variable goes unused
    if (columnId === 'skip-the-log') {
      console.log(columnId);
    }

    const roleIDA = rowA?.original?.orgs?.items[0]?.roleID;
    const roleIDB = rowB?.original?.orgs?.items[0]?.roleID;

    const roleNameA = roleIDA
      ? normalizedOrgRoles[roleIDA]?.roleName
      : 'No Role';
    const roleNameB = roleIDB
      ? normalizedOrgRoles[roleIDB]?.roleName
      : 'No Role';

    // Log for debugging
    // console.log('roleSortingFn:', roleNameA, roleNameB);

    return roleNameA.localeCompare(roleNameB);
  };

  const memberSinceSortingFn = (rowA, rowB, columnId) => {
    // NOTE: coumnId isn't used but the linter won't allow commits if a variable goes unused
    if (columnId === 'skip-the-log') {
      console.log(columnId);
    }
    const dateA = moment(rowA.original.createdAt);
    const dateB = moment(rowB.original.createdAt);

    if (!dateA.isValid() || !dateB.isValid()) {
      return 0; // Handle invalid dates
    }

    return dateA.diff(dateB);
  };

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'name',
        cell: ({ row }) => {
          const user = row.original;
          const { name, title, avatar, initials } = user;
          const roleID = user?.orgs?.items[0]?.roleID;

          const canClickUser = hasPermission(
            PermissionKeys.TeamsPeople_ViewUser,
            [user.id]
          );
          const canClickUserImage = hasPermission(
            PermissionKeys.TeamsPeople_ViewUserStatCard,
            [user.id]
          );

          const canMultiDelete =
            checkPermission(PermissionKeys.TeamsPeople_DeleteUser) ===
            'everyone';

          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingLeft: '20px',
                paddingRight: '20px',
                cursor: canClickUser ? 'pointer' : 'default',
              }}
              onClick={(e) => {
                // Check if the click is not on the Checkbox and user can view user about profile
                if (!e.target.closest('.MuiCheckbox-root') && canClickUser) {
                  navigate(`/people/about/${user.id}`);
                }
              }}
            >
              {/* NOTE: Super admin can't be deleted, do not put a Checkbox for any super admin */}
              {canMultiDelete && !normalizedOrgRoles[roleID]?.isSuper && (
                <Checkbox
                  sx={{
                    // color: '#FF6A00',
                    color: '#868686',
                    '&.Mui-checked': {
                      color: '#FF6A00',
                    },
                    '&:hover': {
                      backgroundColor: 'transparent !important',
                    },
                  }}
                  checked={checkedUsers.some(
                    (checkedUser) => checkedUser.id === user.id
                  )}
                  onChange={(e) => {
                    e.stopPropagation();
                    handleUserCheck(e, user);
                  }}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              )}

              {avatar ? (
                <Avatar
                  src={avatar}
                  sx={{
                    marginRight: '12px',
                    cursor: canClickUserImage ? 'pointer' : 'default',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (canClickUserImage) {
                      navigate(`/people/stats/${user.id}`);
                    }
                  }}
                />
              ) : (
                <Avatar
                  sx={{
                    marginRight: '12px',
                    cursor: canClickUserImage ? 'pointer' : 'default',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (canClickUserImage) {
                      navigate(`/people/stats/${user.id}`);
                    }
                  }}
                >
                  {initials}
                </Avatar>
              )}

              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  minHeight: 77,
                }}
              >
                <Typography style={{ fontSize: '16px', fontWeight: 600 }}>
                  {name}
                </Typography>
                {title && (
                  <Typography
                    style={{
                      minHeight: '16px',
                      visibility: title ? 'visible' : 'hidden',
                      fontFamily: 'Source Sans Pro',
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '24px',
                      letterSpacing: '-0.01em',
                      textAlign: 'left',
                      color: '#868686',
                    }}
                  >
                    {title}
                  </Typography>
                )}
                {/* <p style={{ fontWeight: 600, marginBottom: '-15px' }}>{name}</p>
                <p
                  style={{
                    minHeight: '16px',
                    visibility: title ? 'visible' : 'hidden',
                    fontFamily: 'Source Sans Pro',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    letterSpacing: '-0.01em',
                    textAlign: 'left',
                    color: '#868686',
                  }}
                >
                  {title ?? 'placeholder'}
                </p> */}
              </Box>
            </Box>
          );
        },
        header: () => (
          <Typography style={{ fontSize: 16, fontWeight: 700 }}>
            Name
          </Typography>
        ),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'email',
        cell: (info) => {
          const canClickUser = hasPermission(
            PermissionKeys.TeamsPeople_ViewUser,
            [info.row.original.id]
          );
          return (
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                height: 77,
                paddingLeft: '20px',
                paddingRight: '20px',
              }}
              sx={{ cursor: canClickUser ? 'pointer' : 'default' }}
              onClick={(e) => {
                e.stopPropagation();
                if (canClickUser) {
                  navigate(`/people/about/${info.row.original.id}`);
                }
              }}
            >
              <Typography
                style={{ color: '#323232', fontSize: '14px', fontWeight: 500 }}
              >
                {' '}
                {info.getValue()}{' '}
              </Typography>
            </Box>
          );
        },
        header: () => (
          <Typography style={{ fontSize: 16, fontWeight: 700 }}>
            Email
          </Typography>
        ),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'phoneNumber',
        cell: (info) => {
          const canClickUser = hasPermission(
            PermissionKeys.TeamsPeople_ViewUser,
            [info.row.original.id]
          );
          return (
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                height: 77,
                paddingLeft: '20px',
                paddingRight: '20px',
              }}
              sx={{ cursor: canClickUser ? 'pointer' : 'default' }}
              onClick={(e) => {
                e.stopPropagation();
                if (canClickUser) {
                  navigate(`/people/about/${info.row.original.id}`);
                }
              }}
            >
              <Typography
                style={{ color: '#323232', fontSize: '14px', fontWeight: 500 }}
              >
                {' '}
                {info.getValue()}{' '}
              </Typography>
            </Box>
          );
        },
        header: () => (
          <Typography style={{ fontSize: 16, fontWeight: 700 }}>
            Phone
          </Typography>
        ),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'role',
        cell: ({ row }) => {
          const canClickUser = hasPermission(
            PermissionKeys.TeamsPeople_ViewUser,
            [row.original.id]
          );
          const roleID = row?.original?.orgs?.items[0]?.roleID;
          return (
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                height: 77,
                paddingLeft: '20px',
                paddingRight: '20px',
              }}
              sx={{ cursor: canClickUser ? 'pointer' : 'default' }}
              onClick={(e) => {
                e.stopPropagation();
                if (canClickUser) {
                  navigate(`/people/about/${row.original.id}`);
                }
              }}
            >
              <Typography
                style={{ color: '#323232', fontSize: '14px', fontWeight: 500 }}
              >
                {capitalizeFirstLetter(
                  roleID ? normalizedOrgRoles[roleID]?.roleName : 'No Role'
                )}
              </Typography>
            </Box>
          );
        },
        header: () => (
          <Typography style={{ fontSize: 16, fontWeight: 700 }}>
            Role
          </Typography>
        ),
        footer: (props) => props.column.id,
        sortingFn: roleSortingFn,
      },
      {
        id: 'Member Since',
        accessorFn: (row) => moment(row?.createdAt)?.format('MMM DD, YYYY'),
        cell: ({ row }) => {
          // console.log('UserTable createdAt: ', row.original);
          const canClickUser = hasPermission(
            PermissionKeys.TeamsPeople_ViewUser,
            [row.original.id]
          );
          return (
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                height: 77,
                paddingLeft: '20px',
                paddingRight: '20px',
              }}
              sx={{ cursor: canClickUser ? 'pointer' : 'default' }}
              onClick={(e) => {
                e.stopPropagation();
                if (canClickUser) {
                  navigate(`/people/about/${row.original.id}`);
                }
              }}
            >
              <Typography
                style={{ color: '#323232', fontSize: '14px', fontWeight: 500 }}
              >
                {moment(row?.original.createdAt)?.format('MMM DD, YYYY')}
              </Typography>
            </Box>
          );
        },
        header: () => (
          <Typography style={{ fontSize: 16, fontWeight: 700 }}>
            Member Since
          </Typography>
        ),
        footer: (props) => props.column.id,
        sortingFn: memberSinceSortingFn,
      },
      {
        accessorKey: 'status',
        cell: ({ row }) => {
          const status = row.original.status;
          const isActive = status === 'active';

          const canClickUser = hasPermission(
            PermissionKeys.TeamsPeople_ViewUser,
            [row.original.id]
          );

          return (
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                height: 77,
                paddingLeft: '20px',
                paddingRight: '20px',
              }}
              sx={{ cursor: canClickUser ? 'pointer' : 'default' }}
              onClick={(e) => {
                e.stopPropagation();
                if (canClickUser) {
                  navigate(`/people/about/${row.original.id}`);
                }
              }}
            >
              <Typography
                style={{
                  // color: isActive ? '#01CEAC' : '#FF5C77',
                  color: isActive ? '#01CEAC' : '#E35050',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              >
                {status === 'disabled'
                  ? 'Deactivated'
                  : capitalizeFirstLetter(status)}
              </Typography>
            </Box>
          );
        },
        header: () => (
          <Typography style={{ fontSize: 16, fontWeight: 700 }}>
            Status
          </Typography>
        ),
        footer: (props) => props.column.id,
      },
    ],
    [checkedUsers]
  );

  // const onLoadMore = async () => {
  //   await fetchPeople(true);
  // };

  const handleClearSearch = () => {
    setSearchString('');
    setTotalPages(Math.ceil(users.length / limit));
    setCurrentPage(1);

    // Update redux as well
    dispatch(setSearchStringAction(''));
    dispatch(setTotalPagesAction(Math.ceil(users.length / limit)));
    dispatch(setCurrentPageAction(1));
  };

  // console.log('totalPages: ', totalPages);

  const cancelIconAdornment =
    searchString.length > 0 ? (
      <Box
        sx={{ cursor: 'pointer' }}
        className={'clear-input-button'}
        onClick={handleClearSearch}
      >
        <InputAdornment position="end">
          <GamifyIcon icon={'delete'}></GamifyIcon>
        </InputAdornment>
      </Box>
    ) : (
      <></>
    );

  const renderSearchInputAndAddNewButton = () => {
    const canCreate = checkPermission(PermissionKeys.TeamsPeople_CreateUser);
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',

          padding: '90px 100px 20px 100px',
          // paddingBottom: '40px',
          // backgroundColor: '#FAC8B2',
        }}
      >
        <Box
          style={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <h1
              // onClick={() => console.log('ggg----', filteredList)}
              style={{
                color: '#222428',
                fontSize: '28px',
                fontFamily: 'Manrope',
                lineHeight: '58.51px',
                fontWeight: '600px',
              }}
            >
              People
            </h1>
            {/* <Box style={{ marginLeft: 16 }}>
              <BetaIcon></BetaIcon>
            </Box> */}
          </Box>
          {/* <AddNewButton
            startIcon={<ControlPoint />}
            onClick={() => navigate(`/people/new`)}
            // NOTE: inviteUserModal is no longer used as the nested modal for adding teams resulted in odd behavior
            // NOTE: also to match TeamFormPage, InviteUserPage is now used
            // onClick={() => setIsInviteModalVisible(true)}
          >
            Add User
          </AddNewButton> */}
          {canCreate && (
            <Button
              onClick={() => {
                setShowNewUserFormModal(true);

                // NOTE: No longer need to navigate to a new route, UserFormPage is a modal
                // navigate(`/people/new`);
              }}
              style={{
                backgroundColor: '#FF6A00',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 180,
                height: 48,
                borderRadius: 8,
              }}
              sx={{ cursor: 'pointer' }}
              disableRipple={true}
            >
              <GamifyIcon icon={'add'}></GamifyIcon>
              <Typography
                style={{
                  color: 'white',
                  fontSize: '18px',
                  fontWeight: 700,
                  marginLeft: 10,
                }}
              >
                Add Person
              </Typography>
            </Button>
          )}
        </Box>
        <Box
          style={{
            marginTop: '25px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            // alignItems: 'center',
            overflowX: 'auto',
            overflowY: 'hidden',
          }}
        >
          <Box style={{ position: 'relative', transform: 'translateY(9px)' }}>
            <SearchInput
              fullWidth
              id="search"
              name="new-search"
              variant="outlined"
              placeholder="Search"
              value={searchString}
              onChange={(e) => {
                setSearchString(e.target.value);
                // Move user back to page 1
                setCurrentPage(1);

                // Update redux state as well
                dispatch(setSearchStringAction(e.target.value));
                dispatch(setCurrentPageAction(1));
              }}
              sx={{ marginRight: '16px' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ backgroundColor: 'transparent', width: '10px' }}
                  >
                    <Search sx={{ color: '#868686' }} />
                  </InputAdornment>
                ),
                endAdornment: cancelIconAdornment,
              }}
            />
          </Box>
          <Box display="flex" gap={2}>
            <Box>
              <p
                style={{
                  margin: '0 0 5px 0',
                  fontSize: '14px',
                  fontWeight: '700',
                }}
              >
                Role
              </p>
              <StyledSelect
                style={{ minWidth: '25ch' }}
                MenuProps={{
                  style: { zIndex: 100002 },
                  MenuListProps: {
                    sx: {
                      maxHeight: 300,
                      overflowY: 'auto',
                      paddingTop: 0,
                      paddingBottom: 0,
                      borderRadius: '8px',
                      border: '1px solid #D0D2D8',
                    },
                  },
                }}
                placeholder="HELLO!!"
                onChange={handleFilterChange}
                value={listFilters.role}
                name="role"
              >
                {[{ id: 'all', roleName: 'All' }, ...orgRoles].map((opt, i) => (
                  <StyledMenuItem value={opt.id} key={i}>
                    {capitalizeFirstLetter(opt.roleName)}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </Box>
            <Box>
              <p
                style={{
                  margin: '0 0 5px 0',
                  fontSize: '14px',
                  fontWeight: '700',
                }}
              >
                Status
              </p>
              <StyledSelect
                style={{ minWidth: '25ch' }}
                MenuProps={{
                  style: { zIndex: 100002 },
                  MenuListProps: {
                    sx: {
                      maxHeight: 300,
                      overflowY: 'auto',
                      paddingTop: 0,
                      paddingBottom: 0,
                      borderRadius: '8px',
                      border: '1px solid #D0D2D8',
                    },
                  },
                }}
                placeholder="HELLO!!"
                onChange={handleFilterChange}
                value={listFilters.status}
                name="status"
              >
                {['all', 'active', 'disabled'].map((opt, i) => (
                  <StyledMenuItem value={opt} key={i}>
                    {opt === 'disabled'
                      ? 'Deactivated'
                      : capitalizeFirstLetter(opt)}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </Box>
            <Box>
              <p
                style={{
                  margin: '0 0 5px 0',
                  fontSize: '14px',
                  fontWeight: '700',
                }}
              >
                People per page
              </p>
              <StyledSelect
                style={{ minWidth: '25ch' }}
                MenuProps={{
                  style: { zIndex: 100002 },
                  MenuListProps: {
                    sx: {
                      maxHeight: 300,
                      overflowY: 'auto',
                      paddingTop: 0,
                      paddingBottom: 0,
                      borderRadius: '8px',
                      border: '1px solid #D0D2D8',
                    },
                  },
                }}
                onChange={(e) => {
                  setLimit(e.target.value);
                  // Move user back to page 1
                  setCurrentPage(1);

                  // Update redux state as well
                  dispatch(setUsersPerPageAction(e.target.value));
                  dispatch(setCurrentPageAction(1));
                }}
                value={limit}
                name="limit"
              >
                {[10, 25, 50, 100, 200].map((opt, i) => (
                  <StyledMenuItem value={opt} key={i}>
                    {opt}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </Box>
          </Box>
        </Box>
        {/* <InviteUserModal
          open={isInviteModalVisible}
          onClose={() => {
            setIsInviteModalVisible(false);
            fetchPeople();
          }}
        /> */}
      </Box>
    );
  };
  // console.log('limit', limit);
  return (
    <>
      <UserFormModal
        showUserFormModal={showNewUserFormModal}
        setShowUserFormModal={setShowNewUserFormModal}
        onTriggerUsersRefetch={onTriggerUsersRefetch}
        userId={null}
        user={null}
        zIndex={100000}
      ></UserFormModal>
      <Box sx={{}}>
        {renderSearchInputAndAddNewButton()}

        {/* THIS IS THE RESULT NUMBER DISPLAY DIV!
          <Box
            style={{
              paddingBottom: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              flex: 1,
              background: '#1a1a1a',
            }}
          >
            <Box style={{ marginLeft: '20px' }}>
              {searchString && (
                <ResultsText>{`${users.length} RESULT${
                  users.length === 1 ? '' : 'S'
                }`}</ResultsText>
              )}
            </Box>
            {isFetchingData && (
              <Box style={{ marginRight: '20px' }}>
                <CircularProgress size={18} sx={{ color: 'white' }} />
              </Box>
            )}
          </Box> */}
        <Box
          style={{
            padding: '10px 100px 50px 100px',
          }}
        >
          <Table
            data={
              listFilters.role + listFilters.status !== 'allall'
                ? filteredList
                : searchString.length > 0
                ? searchData
                : users
            }
            columns={columns}
            reduxUsers={reduxUsers}
            reduxProfiles={reduxProfiles}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            checkedUsers={checkedUsers}
            setCheckedUsers={setCheckedUsers}
            onTriggerUsersRefetch={onTriggerUsersRefetch}
            // onLoadMore={onLoadMore}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            isLoading={isLoading}
            limit={limit}
          />
        </Box>
      </Box>
    </>
  );
}

function Table({
  data,
  columns,
  reduxUsers,
  reduxProfiles,
  selectedUser,
  setSelectedUser,
  checkedUsers,
  setCheckedUsers,
  onTriggerUsersRefetch,
  // onLoadMore,
  totalPages,
  currentPage,
  setCurrentPage,
  isLoading,
  limit,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hasPermission, checkPermission, orgRoles, isSuperAdmin } =
    usePermissions();
  const [sorting, setSorting] = React.useState([]);
  // const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isBulkDelete, setIsBulkDelete] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [anchorElMap, setAnchorElMap] = useState({});
  const [showEditUserFormModal, setShowEditUserFormModal] = useState(false);
  // console.log('setCheckedUsers', setCheckedUsers);
  // console.log('onLoadMore', onLoadMore);
  // console.log('selectedUser: ', selectedUser);
  // console.log('checkedUsers: ', checkedUsers);

  const isSuper = isSuperAdmin();

  const normalizedOrgRoles = orgRoles.reduce((acc, role) => {
    acc[role.id] = role;
    return acc;
  }, {});

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    initialState: {
      pagination: {
        pageSize: limit,
      },
    },
    enableSortingRemoval: false,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  // console.log('table: ', table);

  useEffect(() => {
    table.setPageSize(limit);
  }, [limit]);

  const handleDelete = (user) => {
    setIsDeleteModalOpen(true);
    handleMenuClose(user);
  };

  const handleEdit = (user) => {
    // setIsEditModalOpen(true);
    setShowEditUserFormModal(true);

    // NOTE: pass user as a prop to EditUserModal instead, navigate is no longer needed as UserFormPage is now used in a modal and not at a separate '/edit/<userID>' route
    // navigate(`/people/edit/${user.id}`, { state: { user } });

    handleMenuClose(user);
  };

  const handleMenuOpen = (event, user) => {
    setAnchorElMap((prev) => ({ ...prev, [user.id]: event.currentTarget }));
  };

  const handleMenuClose = (user) => {
    setAnchorElMap((prev) => ({ ...prev, [user.id]: null }));
  };

  const fieldsWithSorting = useMemo(
    () => ['name', 'email', 'role', 'Member Since', 'status'],
    []
  );

  //
  //  Use for activating/deactivating users, not deleting
  //
  async function onToggleStatus(user, action) {
    const users = checkedUsers.length > 0 ? checkedUsers : [user];

    try {
      await Promise.all(
        users.map(async (currentUser) => {
          handleMenuClose(currentUser);

          const res = await API.graphql({
            query: mutations.personAdminFunctions,
            variables: {
              userID: currentUser.id,
              username: currentUser.username,
              action,
            },
          });

          handleMenuClose(currentUser);
          return res;
        })
      );

      // NOTE: No longer need to refetch, just update users and profiles in the Users slice of state in the redux store
      // setTimeout(() => {
      //   // This is to ensure that the changes have reflected before refetching the table
      //   onTriggerUsersRefetch();
      // }, 300);

      for (const user of users) {
        const reduxUser = reduxUsers[user.id];
        const reduxProfile = reduxProfiles[user.id];

        const updatedReduxUser = {
          ...reduxUser,
          status: action === 'enable' ? 'active' : 'disabled',
        };

        dispatch(updateUserAction(updatedReduxUser));

        if (reduxProfile) {
          const updatedReduxProfile = {
            ...reduxProfile,
            user: {
              ...reduxProfile.user,
              status: action === 'enable' ? 'active' : 'disabled',
            },
          };

          dispatch(updateProfileAction(updatedReduxProfile));
        }
      }

      if (users.length > 1) {
        GamifyToast.success(
          `Successfully updated status for ${users.length} users`
        );
      } else {
        GamifyToast.success(
          `Successfully updated status for ${users[0]?.name}`
        );
      }
    } catch (error) {
      console.error(error);
      GamifyToast.error('There was a problem updating status');
    }
  }

  //
  //  Do user admin function for deleting a user
  //  TO DO: Eventually refactor for multiple users when bulk deletion is implemented
  //
  async function deleteUsers(users = [], onComplete) {
    // console.log('deleteUsers: ', users);
    if (!users.length) return;

    const numDeletedUsers = users.length;
    try {
      await API.graphql({
        query: mutations.personAdminFunctions,
        variables: {
          userID: '',
          username: '',
          users: JSON.stringify(users),
          action: 'delete',
        },
      });
      // NOTE: Single user delete can be done with userID and username, but this should NOT be used for bulk deleting as it is an n+1 query and is terribly slow.
      // for (const user of users) {
      //   // console.log('deleteUsers: ', user);
      //   await API.graphql({
      //     query: mutations.personAdminFunctions,
      //     variables: {
      //       userID: user.id,
      //       username: user.username,
      //       action: 'delete',
      //     },
      //   });
      // }

      // Remove users and profiles from the redux store, will not need to fetch new users list from db.
      // console.log('removeUsers users: ', users);
      dispatch(removeUsersAction(users.map((user) => user.id)));
      dispatch(removeProfilesAction(users.map((user) => user.id)));
    } catch (err) {
      console.error(
        'deleteUsers(): error changing User(s) status to deleted:',
        err
      );
      if (numDeletedUsers === 1) {
        GamifyToast.error(
          'There was a problem deleting your user. Please try again.'
        );
      } else {
        GamifyToast.error(
          'There was a problem deleting your users. Please try again.'
        );
      }
      onComplete(false);
      return false;
    }

    onComplete(true);
    return true;
  }

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
    table.previousPage();

    // Update redux state as well
    dispatch(setCurrentPageAction(currentPage - 1));
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    table.nextPage();

    // Update redux state as well
    dispatch(setCurrentPageAction(currentPage + 1));
  };

  const handleSetPage = (i) => {
    setCurrentPage(i + 1);
    table.setPageIndex(i);

    // Update redux state as well
    dispatch(setCurrentPageAction(i + 1));
  };

  // Needed for when search/filter change resets currentPage to page 1
  useEffect(() => {
    // Conditional prevents page setting when there's no need for pagination
    if (data.length > limit) {
      table.setPageIndex(currentPage - 1);
    }
  }, [currentPage]);

  return (
    <>
      {selectedUser?.id && (
        <UserFormModal
          showUserFormModal={showEditUserFormModal}
          setShowUserFormModal={setShowEditUserFormModal}
          onTriggerUsersRefetch={onTriggerUsersRefetch}
          userId={selectedUser.id}
          user={selectedUser}
          zIndex={100000}
        ></UserFormModal>
      )}
      <TableContainer
      // sx={{
      //   minHeight: '50vh',
      // }}
      >
        <MuiTable>
          <TableHead>
            {table?.getHeaderGroups()?.map((headerGroup) => (
              <UsersTableRow key={headerGroup.id} sx={{ borderBottom: 'none' }}>
                {headerGroup.headers?.map((header) => {
                  return (
                    <TitleTableCell
                      key={header.id}
                      colSpan={header.colSpan}
                      sx={{ borderBottom: 'none' }}
                    >
                      {header.isPlaceholder ? null : (
                        <Box
                          {...{
                            // NOTE: If less customization on which columns sort, can use header.column.getCanSort() instead of fieldsWithSorting.includes(header.id)
                            className: fieldsWithSorting.includes(header.id)
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: fieldsWithSorting.includes(header.id)
                              ? header.column.getToggleSortingHandler()
                              : () => {},
                          }}
                          style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            cursor: fieldsWithSorting.includes(header.id)
                              ? 'pointer'
                              : 'default',
                            borderBottom: 'none',
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {fieldsWithSorting.includes(header.id) && (
                            <img src="/images/sort-icon.svg" />
                          )}
                        </Box>
                      )}
                    </TitleTableCell>
                  );
                })}
                {/* NOTE: This is the header for the options menu category, this column will always render for now until a way of determining whether there's at least one rendered menu can be determined */}
                {<TitleTableCell sx={{ borderBottom: 'none' }} />}
              </UsersTableRow>
            ))}
          </TableHead>
          <Box style={{ height: '10px', width: '100%' }} />
          {/* <TableBody sx={{ backgroundColor: '#F0F0F3' }}> */}
          <TableBody>
            {table?.getRowModel().rows.map((row) => {
              const roleID = row?.original?.orgs?.items[0]?.roleID;

              const canClickUser = hasPermission(
                PermissionKeys.TeamsPeople_ViewUser,
                [row.original.id]
              );

              const showEdit = hasPermission(
                PermissionKeys.TeamsPeople_UpdateUser,
                [row.original.id]
              );
              const showChangeStatus = checkPermission(
                PermissionKeys.TeamsPeople_ChangePersonsStatus
              );
              const showDelete = hasPermission(
                PermissionKeys.TeamsPeople_DeleteUser,
                [row.original.id]
              );

              const showMenu = showEdit || showChangeStatus || showDelete;
              return (
                <>
                  <UsersTableRow key={row.id} sx={{ borderBottom: 'none' }}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <UsersTableCell
                          key={cell.id}
                          sx={{ borderBottom: 'none', padding: 0 }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </UsersTableCell>
                      );
                    })}

                    {
                      // || row.original.id === global.me.id
                      <UsersTableCell
                        sx={{
                          borderBottom: 'none',
                          cursor: canClickUser ? 'pointer' : 'default',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          // Check if the click is not on the IconButton and if current user has permission to click user
                          if (
                            !e.target.closest('.MuiIconButton-root') &&
                            canClickUser
                          ) {
                            navigate(`/people/about/${row.original.id}`);
                          }
                        }}
                      >
                        {showMenu && (
                          <>
                            <IconButton
                              color="white"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedUser(row.original);
                                handleMenuOpen(e, row.original);
                              }}
                            >
                              <MoreHoriz style={{ color: '#868686' }} />
                            </IconButton>
                            <CustomMenu
                              anchorEl={anchorElMap[row.original.id]}
                              open={Boolean(anchorElMap[row.original.id])}
                              onClose={(e) => {
                                e.stopPropagation();
                                handleMenuClose(row.original);
                              }}
                            >
                              {showEdit &&
                                // NOTE: The super user cannot be edited by non-super users, verify the user of the profile is either not a super admin OR the profile user is a super admin AND the current logged in user is a super admin before showing the edit button
                                (!normalizedOrgRoles[roleID]?.isSuper ||
                                  (isSuper &&
                                    normalizedOrgRoles[roleID]?.isSuper)) && (
                                  <CustomMenuItem
                                    sx={{
                                      background: 'white',
                                      color: 'black',
                                      borderRadius: '4px 4px 0px 0px',
                                      paddingTop: '9px',
                                      paddingBottom: '9px',
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleEdit(row.original);
                                    }}
                                  >
                                    Edit
                                  </CustomMenuItem>
                                )}
                              {showChangeStatus && (
                                <CustomMenuItem
                                  sx={{
                                    background: 'white',
                                    color: 'black',
                                    paddingTop: '9px',
                                    paddingBottom: '9px',
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    onToggleStatus(
                                      row.original,
                                      row?.original.status === 'active'
                                        ? 'disable'
                                        : 'enable'
                                    );
                                  }}
                                >
                                  {row?.original.status === 'active'
                                    ? 'Deactivate'
                                    : 'Activate'}
                                </CustomMenuItem>
                              )}
                              {showDelete &&
                                !normalizedOrgRoles[roleID]?.isSuper && (
                                  <CustomMenuItem
                                    sx={{
                                      background: 'white',
                                      color: 'red',
                                      borderRadius: '0px 0px 4px 4px',
                                      paddingTop: '9px',
                                      paddingBottom: '9px',
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDelete(row.original);
                                    }}
                                  >
                                    Delete
                                  </CustomMenuItem>
                                )}
                            </CustomMenu>
                          </>
                        )}
                      </UsersTableCell>
                    }
                  </UsersTableRow>
                  <Box style={{ height: '10px', width: '100%' }} />
                </>
              );
            })}
          </TableBody>
        </MuiTable>

        {/* <EditUserModal
          user={selectedUser}
          open={isEditModalOpen}
          onClose={() => {
            setIsEditModalOpen(false);
          }}
          onTriggerTableRefetch={() => onRefetchTableData()}
        /> */}
        {/* <DeleteUserModal
          users={checkedUsers.length ? checkedUsers : [selectedUser]}
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={() => {
            onRefetchTableData();
          }}
        /> */}
        <DynamicConfirmModal
          showDynamicConfirmModal={isDeleteModalOpen}
          setShowDynamicConfirmModal={setIsDeleteModalOpen}
          zIndex={100002}
          title={
            isBulkDelete
              ? checkedUsers.length === 1
                ? `Delete ${checkedUsers[0].name}?`
                : `Delete ${checkedUsers.length} users?`
              : `Delete ${selectedUser?.name}?`
          }
          subtitle={`You won’t be able to revert this\naction.`}
          // subtitleColor={dynamicModalObj?.subtitleColor}
          // icon={dynamicModalObj?.icon}
          // iconBackground={dynamicModalObj?.iconBackground}
          // confirmButtonColor={'#FF5C77'}
          confirmButtonColor={'#E35050'}
          isSaving={isSaving}
          onCancel={
            isBulkDelete
              ? () => {
                  setIsBulkDelete(false);
                  setIsDeleteModalOpen(false);
                }
              : () => {
                  setIsDeleteModalOpen(false);
                }
          }
          onConfirm={() => {
            setIsSaving(true);
            deleteUsers(isBulkDelete ? checkedUsers : [selectedUser], (res) => {
              setIsSaving(false);
              if (res) {
                setIsDeleteModalOpen(false);

                // NOTE: Refetch not needed due to use of redux, this store update is handled in the deleteUsers function
                // setTimeout(() => {
                //   onTriggerUsersRefetch();
                // }, 750);

                GamifyToast.success(
                  isBulkDelete
                    ? checkedUsers.length === 1
                      ? `${checkedUsers[0].name} deleted successfully`
                      : `${checkedUsers.length} users deleted successfully`
                    : `${selectedUser.name} deleted successfully`
                );

                if (isBulkDelete) {
                  // Clear checkedUsers if a bulk user deletion was done
                  setCheckedUsers([]);
                } else {
                  // Otherwise, filter the one deleted selectedUser from checkedUsers
                  setCheckedUsers((prevCheckedUsers) =>
                    prevCheckedUsers.filter(
                      (checkedUser) => checkedUser.id !== selectedUser.id
                    )
                  );
                }
              }
            });
          }}
        ></DynamicConfirmModal>
      </TableContainer>
      {/* Only show pagination when there's more users than users per page */}
      {data.length > limit && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 2,
          }}
        >
          <NavigationButton
            onClick={handlePrevPage}
            disabled={!table.getCanPreviousPage()}
          >
            {/* <ChevronLeft style={{ fontSize: 30 }} /> */}
            <img
              src={
                table.getCanPreviousPage()
                  ? '/images/previous-page.svg'
                  : '/images/previous-page-disabled.svg'
              }
              style={{ height: '30px' }}
            />
          </NavigationButton>
          {Array.from({ length: totalPages }, (_, i) => (
            // <PageButton
            //   key={i}
            // isSelected={currentPage === i + 1}
            // onClick={() => handleSetPage(i)}
            // >
            //   {i + 1}
            // </PageButton>
            <Box
              key={i}
              // isSelected={currentPage === i + 1}
              onClick={() => handleSetPage(i)}
              className="hov"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '16px',
                fontWeight: 'bold',
                margin: '0 10px',
                color: `${currentPage === i + 1 ? '#FF6A00' : 'gray'}`,
                border: `2px solid ${
                  currentPage === i + 1 ? '#FF6A00' : 'gray'
                }`,
                borderRadius: '2px',
                width: '40px',
                height: '40px',
              }}
            >
              {i + 1}
            </Box>
          ))}
          <NavigationButton
            onClick={handleNextPage}
            disabled={!table.getCanNextPage()}
          >
            {/* <ChevronRight style={{ fontSize: 30 }} /> */}
            <img
              src={
                table.getCanNextPage()
                  ? '/images/next-page.svg'
                  : '/images/next-page-disabled.svg'
              }
              style={{ height: '30px' }}
            />
          </NavigationButton>
        </Box>
      )}
      {/* While loading during search, there are 0 results. If loading, show animation. Otherwise display 'No results' */}
      {/* {console.log('searchUsers data: ', data)} */}
      {data.length === 0 && (
        <Box
          style={{
            backgroundColor: '#F0F0F3',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 72,
            borderRadius: 8,
          }}
        >
          {/* {console.log('searchUsers isLoading: ', isLoading)} */}
          {isLoading ? (
            <CircularProgress style={{ color: 'black' }}></CircularProgress>
          ) : (
            <Typography
              style={{ color: '#323232', fontSize: 16, fontWeight: 500 }}
            >
              No results
            </Typography>
          )}
        </Box>
      )}
      {checkedUsers.length > 0 && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
            height: 116,
            borderTop: '1px solid #D0D2D8',
            marginTop: '24px',
          }}
        >
          <Box
            onClick={() => {
              setIsBulkDelete(true);
              setIsDeleteModalOpen(true);
            }}
            style={{
              // backgroundColor: '#FF5C77',
              backgroundColor: '#E35050',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 228,
              height: 52,
              padding: '24px 16px 24px 16px',
              borderRadius: 8,
            }}
            sx={{ cursor: 'pointer' }}
          >
            <Typography
              style={{ color: 'white', fontSize: '16px', fontWeight: 700 }}
            >
              {checkedUsers.length === 1
                ? `Delete (${checkedUsers.length}) user`
                : `Delete (${checkedUsers.length}) users`}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}
