import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { getAppointmentGrid } from '../../../../graphql/queries';
import { convertAppointmentsToEvents } from './utils';
import { CalendarEvent } from './types';

interface GetAppointmentGridResponse {
  getAppointmentGrid: string;
}

export const fetchAppointmentGrid = async (
  selectedDates: Date[],
  userIds: string[]
): Promise<CalendarEvent[]> => {
  try {
    const request = {
      dates: selectedDates.map((date) => date.toISOString().split('T')[0]),
      users: userIds,
      dealDisplayFields: [
        'First Name',
        'Last Name',
        'FirstName',
        'LastName',
        'Address',
        'City',
        'State',
        'Zip',
        'Zip Code',
        'ZipCode',
        'Zipcode',
        'Phone Number',
        'PhoneNumber',
        'Phone',
        'Notes',
        'Appointment Notes',
        'AppointmentNotes',
      ],
    };

    const response = (await API.graphql(
      graphqlOperation(getAppointmentGrid, {
        request: JSON.stringify(request),
      })
    )) as GraphQLResult<GetAppointmentGridResponse>;

    if (response.data) {
      return convertAppointmentsToEvents(response.data.getAppointmentGrid);
    }
    return [];
  } catch (error) {
    console.error('Error fetching appointment grid:', error);
    return [];
  }
};
