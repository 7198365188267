import React, { useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameMonth,
  isSameDay,
  addMonths,
  subMonths,
} from 'date-fns';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const CalendarMessage = styled(Typography)`
  color: #6b7280;
  font-size: 14px;
  padding: 12px 16px;
  border-bottom: 1px solid #e5e7eb;
`;

const Calendar = styled(Box)`
  padding: 16px;
  background: white;
  border-radius: 8px;
  width: 300px;
`;

const CalendarHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const CalendarGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;

  & > button {
    aspect-ratio: 1;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    color: #6b7280;
    font-size: 14px;

    &:hover:not(:disabled) {
      background-color: #f3f4f6;
    }

    &.selected {
      background-color: #ff6a00;
      color: white;
    }

    &.today:not(.selected) {
      color: #ff6a00;
      font-weight: 600;
    }

    &:disabled {
      color: #d1d5db;
      cursor: not-allowed;
    }

    &.other-month {
      color: #9ca3af;
    }
  }
`;

interface DateRangePickerProps {
  selectedDates: Date[];
  onDateSelect: (date: Date) => void;
  onClose: () => void;
}

export const DateRangePicker = ({
  selectedDates,
  onDateSelect,
  onClose,
}: DateRangePickerProps) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const days = eachDayOfInterval({
    start: startOfMonth(currentMonth),
    end: endOfMonth(currentMonth),
  });

  const weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

  const handleDateClick = (day: Date) => {
    const isDateSelected = selectedDates.some((d: Date) => isSameDay(d, day));

    if (!isDateSelected && selectedDates.length >= 3) {
      onDateSelect(day);
      onClose();
      return;
    }

    onDateSelect(day);
  };

  return (
    <Box style={{ backgroundColor: 'white' }}>
      <CalendarMessage>Select days (up to 3)</CalendarMessage>
      <Calendar>
        <CalendarHeader>
          <IconButton
            onClick={() => setCurrentMonth((prev) => subMonths(prev, 1))}
          >
            <ArrowBackIosNewIcon sx={{ fontSize: 16 }} />
          </IconButton>
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            {format(currentMonth, 'MMMM yyyy')}
          </Typography>
          <IconButton
            onClick={() => setCurrentMonth((prev) => addMonths(prev, 1))}
          >
            <ArrowForwardIosIcon sx={{ fontSize: 16 }} />
          </IconButton>
        </CalendarHeader>

        <CalendarGrid>
          {weekDays.map((day) => (
            <button key={day} disabled style={{ fontWeight: 600 }}>
              {day}
            </button>
          ))}

          {days.map((day) => {
            const isSelected = selectedDates.some((d: Date) =>
              isSameDay(d, day)
            );
            const isToday = isSameDay(day, new Date());

            return (
              <button
                key={day.toISOString()}
                onClick={() => handleDateClick(day)}
                className={`
                  ${isSelected ? 'selected' : ''}
                  ${isToday ? 'today' : ''}
                  ${!isSameMonth(day, currentMonth) ? 'other-month' : ''}
                `}
              >
                {format(day, 'd')}
              </button>
            );
          })}
        </CalendarGrid>
      </Calendar>
    </Box>
  );
};
