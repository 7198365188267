import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';

export const DateHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: white;
  border-bottom: 1px solid #e5e7eb;
`;

export const DateSelector = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: white;

  &:hover {
    background-color: #eceeed;
  }
`;

export const DateDisplay = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const DayNumber = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  color: #ff6a00;
  line-height: 1;
`;

export const DayName = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  color: #29373b;
  text-transform: uppercase;
`;
