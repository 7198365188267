import React, { useState } from 'react';
import {
  Box,
  Typography,
  Checkbox,
  Paper,
  Stack,
  Avatar,
  Chip,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import { Employee } from './useUsersQuery';
import { TeamWithClosers } from './types';

const StyledSidebar = styled(Paper)(({ theme }) => ({
  width: 300,
  height: 'calc(100vh - 70px)',
  borderRight: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
  marginTop: 70,
  transition: 'width 0.3s ease',
  position: 'relative',
}));

const CollapsedSidebar = styled(Paper)(({ theme }) => ({
  width: 50,
  height: 'calc(100vh - 70px)',
  borderRight: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
  marginTop: 70,
  transition: 'width 0.3s ease',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const SearchInput = styled('input')`
  padding: 8px 12px 8px 48px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
  outline: none;
  height: 48px;

  &:focus {
    border-color: #ff6a00;
  }

  &::placeholder {
    color: #9ca3af;
  }
`;

const SearchWrapper = styled(Box)`
  position: relative;

  & svg {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #9ca3af;
  }
`;

const TabsContainer = styled(Box)`
  display: flex;
  gap: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e7eb;
  justify-content: center;
  align-items: center;
`;

const TabButton = styled(Typography)<{ active: boolean }>`
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: ${({ active }) => (active ? '#ff6a00' : '#6b7280')};
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -9px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #ff6a00;
    opacity: ${({ active }) => (active ? 1 : 0)};
  }
`;

const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(1.4)};
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: #fff;
  margin: -16px -16px 16px -16px;
`;

interface SchedulingSidebarProps {
  activeTab: 'people' | 'team';
  setActiveTab: (tab: 'people' | 'team') => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  isLoading: boolean;
  filteredEmployees: Employee[];
  selectedEmployees: string[];
  handleEmployeeSelection: (id: string) => void;
  colorAssignments: Record<string, string>;
  employees: Employee[];
  teamsWithClosers: TeamWithClosers[];
  handleTeamSelection: (id: string) => void;
  handleTeamMemberSelection: (employeeId: string, teamId: string) => void;
}

export const SchedulingSidebar: React.FC<SchedulingSidebarProps> = ({
  activeTab,
  setActiveTab,
  searchQuery,
  setSearchQuery,
  isLoading,
  filteredEmployees,
  selectedEmployees,
  handleEmployeeSelection,
  colorAssignments,
  employees,
  teamsWithClosers,
  handleTeamSelection,
  handleTeamMemberSelection,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const renderTeamMembers = () => (
    <Stack spacing={2}>
      {teamsWithClosers.map((team) => (
        <Box key={team.id}>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              p: '4px',
              borderRadius: 1,
              backgroundColor: 'background.paper',
              '&:hover': { bgcolor: 'action.hover' },
            }}
          >
            <Avatar
              src={team.image}
              alt={team.name}
              sx={{
                width: 24,
                height: 24,
                mr: 1,
                bgcolor: '#BDBDBD',
                fontSize: '0.75rem',
                fontWeight: 500,
              }}
            >
              {team.name.slice(0, 2).toUpperCase()}
            </Avatar>
            <Typography flex={1} variant="subtitle2">
              {team.name}
            </Typography>
            <Checkbox
              checked={team.isSelected}
              onChange={() => handleTeamSelection(team.id)}
              size="small"
              sx={{
                color: '#e5e7eb',
                '&.Mui-checked': {
                  color: '#ff6a00',
                },
              }}
            />
          </Box>

          {team.isSelected && team.closers.length > 0 && (
            <Stack spacing={1} sx={{ ml: 3, mt: 1 }}>
              {team.closers.map((closer) => (
                <Box
                  key={closer.id}
                  display="flex"
                  alignItems="center"
                  sx={{
                    p: '4px',
                    borderRadius: 1,
                    backgroundColor: 'background.paper',
                    '&:hover': { bgcolor: 'action.hover' },
                  }}
                >
                  <Avatar
                    src={closer.avatar}
                    alt={closer.name}
                    sx={{ width: 24, height: 24, mr: 1, fontSize: '0.75rem' }}
                  />
                  <Typography flex={1} variant="body2">
                    {closer.name}
                  </Typography>
                  <Checkbox
                    checked={selectedEmployees.includes(closer.id)}
                    onChange={() =>
                      handleTeamMemberSelection(closer.id, team.id)
                    }
                    size="small"
                    sx={{
                      color: '#e5e7eb',
                      '&.Mui-checked': {
                        color: colorAssignments[closer.id] || '#ff6a00',
                      },
                    }}
                  />
                </Box>
              ))}
            </Stack>
          )}
        </Box>
      ))}
    </Stack>
  );

  if (isCollapsed) {
    return (
      <CollapsedSidebar>
        <IconButton onClick={() => setIsCollapsed(false)} sx={{ mb: 2 }}>
          <MenuIcon />
        </IconButton>
        {selectedEmployees.map((id) => {
          const employee = employees.find((emp) => emp.id === id);
          return (
            employee && (
              <Avatar
                key={employee.id}
                src={employee.avatar}
                alt={employee.name}
                sx={{
                  width: 32,
                  height: 32,
                  mb: 1,
                  border: '2px solid',
                  borderColor: colorAssignments[employee.id] || '#e8f3e3',
                }}
              />
            )
          );
        })}
      </CollapsedSidebar>
    );
  }

  return (
    <StyledSidebar sx={{ backgroundColor: '#ecedec', pt: 5 }}>
      <Header>
        <Typography variant="h6" sx={{ fontWeight: 600, color: '#2c2c2c' }}>
          Scheduling
        </Typography>
        <IconButton onClick={() => setIsCollapsed(true)}>
          <MenuIcon />
        </IconButton>
      </Header>

      <Stack spacing={2} height="100%">
        <TabsContainer>
          <TabButton
            active={activeTab === 'people'}
            onClick={() => setActiveTab('people')}
          >
            People
          </TabButton>
          <TabButton
            active={activeTab === 'team'}
            onClick={() => setActiveTab('team')}
          >
            Team
          </TabButton>
        </TabsContainer>

        <SearchWrapper>
          <SearchIcon fontSize="small" color="action" />
          <SearchInput
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </SearchWrapper>

        <Stack spacing={2} flex={0.5}>
          <Box sx={{ flex: 1 }}>
            {isLoading ? (
              <Box display="flex" justifyContent="center" p={2}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              <Stack
                spacing={1}
                sx={{
                  height: '100%',
                  maxHeight: '50vh',
                  overflowY: 'auto',
                  pr: 1,
                  mr: -1,
                }}
              >
                {activeTab === 'people'
                  ? filteredEmployees.map((employee) => (
                      <Box
                        key={employee.id}
                        display="flex"
                        alignItems="center"
                        sx={{
                          p: '4px',
                          borderRadius: 1,
                          backgroundColor: 'background.paper',
                          '&:hover': { bgcolor: 'action.hover' },
                        }}
                      >
                        <Avatar
                          src={employee.avatar}
                          alt={employee.name}
                          sx={{ width: 24, height: 24, mr: 1 }}
                        />
                        <Typography flex={1} variant="body2">
                          {employee.name}
                        </Typography>
                        <Checkbox
                          checked={selectedEmployees.includes(employee.id)}
                          onChange={() => handleEmployeeSelection(employee.id)}
                          size="small"
                          sx={{
                            color: '#e5e7eb',
                            '&.Mui-checked': {
                              color: colorAssignments[employee.id] || '#ff6a00',
                            },
                          }}
                        />
                      </Box>
                    ))
                  : renderTeamMembers()}
              </Stack>
            )}
          </Box>

          {selectedEmployees.length > 0 && (
            <Box sx={{ borderTop: '1px solid #e5e7eb' }}>
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: '600', mb: 1 }}
                gutterBottom
              >
                Selected Members
              </Typography>
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {selectedEmployees.map((id) => {
                  const employee = employees.find((emp) => emp.id === id);
                  return (
                    employee && (
                      <Chip
                        key={employee.id}
                        avatar={<Avatar src={employee.avatar} />}
                        label={employee.name}
                        onDelete={() => handleEmployeeSelection(employee.id)}
                        size="small"
                        sx={{
                          bgcolor: colorAssignments[employee.id] || '#e8f3e3',
                          '& .MuiAvatar-root': {
                            border: '1px solid rgba(255,255,255,0.2)',
                          },
                        }}
                      />
                    )
                  );
                })}
              </Stack>
            </Box>
          )}
        </Stack>
      </Stack>
    </StyledSidebar>
  );
};
