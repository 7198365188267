import React, { useEffect, useState, useMemo } from 'react';
import { Button, SearchField, Text, TextField } from '@aws-amplify/ui-react';
import {
  Box,
  Table as MuiTable,
  TableBody,
  TableContainer,
  TableHead,
  Modal,
  Checkbox,
} from '@mui/material';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';
import {
  TitleTableCell,
  UsersTableCell,
  UsersTableRow,
} from '../users/TableComponents';
import gql from 'graphql-tag';
import { API } from 'aws-amplify';

export default function FeaturesTable({ orgsList }) {
  const [checkedOrgs, setCheckedOrgs] = useState({});
  const [featureFlagList, setFeatureFlagList] = useState({});
  const [currentFeature, setCurrentFeature] = useState(null);
  const [orgsDict, setOrgsDict] = useState({});
  const [searchString, setSearchString] = useState('');
  const [filteredOrgsList, setFilteredOrgsList] = useState(() => orgsList);

  useEffect(() => {
    const featuresFlagObj = {};
    const checkedListTemp = {};
    const orgsDictTemp = {};

    // NOTE: Only the feature flags from the paiv org are to be used!
    const orgsListPaiv = orgsList.filter((org) => org.id === 'paiv');
    const paivOrg = orgsListPaiv[0];

    const paivFeatureFlagsObj = paivOrg.featureFlags;
    const paivFeatureFlagsArr = Object.keys(paivFeatureFlagsObj);
    const sortedFeatureFlags = paivFeatureFlagsArr.sort();

    orgsList.forEach((org) => {
      checkedListTemp[org.id] = false;
      orgsDictTemp[org.id] = org;
      // const { featureFlags } = org;

      // NOTE: Switched to only use featureFlags for the paiv org and not all orgs so there's a source of truth for feature flags
      // Object.keys(featureFlags).forEach((ff) => {
      sortedFeatureFlags.forEach((ff) => {
        if (!featuresFlagObj[ff]) {
          featuresFlagObj[ff] = { [org.id]: org.featureFlags[ff] };
        } else {
          featuresFlagObj[ff][org.id] = org.featureFlags[ff];
        }
      });
    });
    setOrgsDict(orgsDictTemp);
    setFeatureFlagList(featuresFlagObj);
    setCheckedOrgs(checkedListTemp);
  }, []);

  useEffect(() => {
    filterSearch(searchString);
  }, [searchString]);

  const filterSearch = (searchString) => {
    setFilteredOrgsList(
      orgsList.filter((el) => el.id.includes(searchString.toLowerCase()))
    );
  };

  const doUpdates = async (action, orgs, email) => {
    const myMutation = gql`
      mutation UpdateOrg($input: UpdateOrgInput!) {
        updateOrg(input: $input) {
          id
          configParms
        }
      }
    `;

    // const updatedOrgs = [];
    for (const org of orgs) {
      // const orgTemp = { id: org };
      const newConfigParms = orgsDict[org].configParms;
      if (action === 'enable') {
        newConfigParms.featureFlags[currentFeature] = ['everyone'];
      } else if (
        action === 'disable' &&
        newConfigParms.featureFlags[currentFeature]
      ) {
        delete newConfigParms.featureFlags[currentFeature];
      } else if (action === 'email') {
        newConfigParms.featureFlags[currentFeature] = email;
      }
      try {
        await API.graphql({
          query: myMutation,
          variables: {
            input: {
              id: org,
              configParms: JSON.stringify(newConfigParms),
            },
          },
        });
      } catch (err) {
        console.log('ERROR--', err);
      }
    }

    setCurrentFeature(null);
  };

  const headerColumn = useMemo(
    () => [
      {
        header: () => 'Org',
        accessorKey: 'id',
        cell: ({ row }) => {
          const { id } = row.original;
          return (
            <Box
              onClick={() => setCheckedOrgs((p) => ({ ...p, [id]: !p[id] }))}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Checkbox
                sx={{
                  color: '#FF6A00',
                  '&.Mui-checked': {
                    color: '#FF6A00',
                  },
                }}
                checked={Boolean(checkedOrgs[id])}
                onClick={(e) => e.stopPropagation()}
                onChange={() => setCheckedOrgs((p) => ({ ...p, [id]: !p[id] }))}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <Text
                style={{
                  fontWeight: '500',
                  fontSize: '1.2rem',
                }}
              >
                {id}
              </Text>
            </Box>
          );
        },
      },
    ],
    [checkedOrgs]
  );
  // const featuresColumns = useMemo(
  //   () => [
  //     ...Object.keys(featuresList).map((ft) => ({
  //       header: () => ft[0].toUpperCase() + ft.slice(1),
  //       accessorKey: ft,
  //       cell: ({ row }) => {
  //         const { features } = row.original;
  //         return (
  //           <Box
  //             sx={{
  //               display: 'flex',
  //               flexDirection: 'row',
  //               alignItems: 'center',
  //             }}
  //           >
  //             <Checkbox
  //               sx={{
  //                 color: '#FF6A00',
  //                 '&.Mui-checked': {
  //                   color: '#FF6A00',
  //                 },
  //               }}
  //               checked={features[ft]?.enabled}
  //               // onChange={() => handleOrgCheck(ft)}
  //               inputProps={{ 'aria-label': 'primary checkbox' }}
  //             />
  //           </Box>
  //         );
  //       },
  //     })),
  //   ],
  //   [featuresList]
  // );
  const featueFlagsColumns = useMemo(
    () => [
      ...Object.keys(featureFlagList).map((ff) => ({
        header: ff[0].toUpperCase() + ff.slice(1),
        accessorKey: ff,
        cell: ({ row }) => {
          // const { featureFlags } = row.original;
          const featureFlagValue = row.original.featureFlags[ff];

          const handleClick = () => {
            setCheckedOrgs((p) => ({ ...p, [row.original.id]: true }));
            setCurrentFeature(ff);
          };
          if (!featureFlagValue) {
            return (
              <Box onClick={handleClick} style={{ color: 'gray' }}>
                Disabled
              </Box>
            );
          } else {
            return (
              <Box
                onClick={handleClick}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  maxWidth: '35ch',
                  maxHeight: '10ch',
                  overflowX: 'auto',
                }}
              >
                <Text
                  style={{
                    color:
                      featureFlagValue[0] === 'everyone' ? 'green' : 'blue',
                  }}
                >
                  {featureFlagValue[0] === 'everyone'
                    ? 'Everyone'
                    : featureFlagValue?.join(', ') || featureFlagValue}
                </Text>
              </Box>
            );
          }
        },
      })),
    ],
    [featureFlagList]
  );
  //   const columns = useMemo(
  //     () => [
  //       {
  //         header: () => 'Org',
  //         accessorKey: 'id',
  //         cell: ({ row }) => {
  //           const { id } = row.original;
  //           return (
  //             <Box
  //               onClick={() => console.log(checkedOrgs)}
  //               sx={{
  //                 display: 'flex',
  //                 flexDirection: 'row',
  //                 alignItems: 'center',
  //               }}
  //             >
  //               <Checkbox
  //                 sx={{
  //                   color: '#FF6A00',
  //                   '&.Mui-checked': {
  //                     color: '#FF6A00',
  //                   },
  //                 }}
  //                 checked={Boolean(checkedOrgs[id])}
  //                 // checked={Boolean(checkedOrgs[id])}
  //                 // onChange={() => handleOrgCheck(id)}
  //                 onChange={() => setCheckedOrgs((p) => ({ ...p, [id]: !p[id] }))}
  //                 inputProps={{ 'aria-label': 'primary checkbox' }}
  //               />
  //               <Text
  //                 style={{
  //                   fontWeight: '500',
  //                   fontSize: '1.2rem',
  //                 }}
  //               >
  //                 {id}
  //               </Text>
  //             </Box>
  //           );
  //         },
  //       },
  //       ...Object.keys(featuresList).map((ft) => ({
  //         header: () => ft[0].toUpperCase() + ft.slice(1),
  //         accessorKey: ft,
  //         cell: ({ row }) => {
  //           const { features } = row.original;
  //           return (
  //             <Box
  //               sx={{
  //                 display: 'flex',
  //                 flexDirection: 'row',
  //                 alignItems: 'center',
  //               }}
  //             >
  //               <Checkbox
  //                 sx={{
  //                   color: '#FF6A00',
  //                   '&.Mui-checked': {
  //                     color: '#FF6A00',
  //                   },
  //                 }}
  //                 checked={features[ft]?.enabled}
  //                 // onChange={() => handleOrgCheck(ft)}
  //                 inputProps={{ 'aria-label': 'primary checkbox' }}
  //               />
  //             </Box>
  //           );
  //         },
  //       })),
  //       ...Object.keys(featureFlagList).map((ff) => ({
  //         header: ff[0].toUpperCase() + ff.slice(1),
  //         accessorKey: ff,
  //         cell: ({ row }) => {
  //           const { featureFlags } = row.original;
  //           return (
  //             <Box
  //               sx={{
  //                 display: 'flex',
  //                 flexDirection: 'row',
  //                 alignItems: 'center',
  //                 maxWidth: '15ch',
  //                 overflowX: 'auto',
  //               }}
  //             >
  //               {featureFlags[ff]}
  //             </Box>
  //           );
  //         },
  //       })),
  //     ],
  //     [featuresList, featureFlagList, checkedOrgs]
  //   );
  return (
    <Box>
      <Box>
        <Text fontWeight="bold">
          {
            'Click on an org featureFlag cell or select multiple orgs and click the feature flag column header to update '
          }
        </Text>
        <Text>
          {
            '* updating specific email in feature flag access can only be changed for one org at a time'
          }
        </Text>
      </Box>
      <Box
        style={{
          display: 'flex',
          flex: 1,
          backgroundColor: 'white',
          padding: 3,
        }}
      >
        <Text color="#FF6A00" fontWeight="bold">
          Selected orgs:{' '}
        </Text>
        <Text color="#FF6A00">
          {Object.keys(checkedOrgs)
            .filter((el) => checkedOrgs[el])
            .join('  ||  ')}
        </Text>
        <div style={{ flexGrow: 1 }}></div>
        <Text
          color="#FF6A00"
          fontWeight="bold"
          onClick={() => {
            const temp = { ...checkedOrgs };
            for (const org in temp) {
              temp[org] = false;
            }
            setCheckedOrgs(temp);
          }}
        >
          Deselect All
        </Text>
      </Box>
      <SearchField
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
        onClear={() => setSearchString('')}
      />
      <Table
        data={filteredOrgsList}
        // data={orgsList}
        columns={[...headerColumn, ...featueFlagsColumns]}
        checkedOrgs={checkedOrgs}
        modalSetter={(val) => {
          const checkedArray = Object.keys(checkedOrgs).filter(
            (el) => checkedOrgs[el]
          );
          if (checkedArray.length >= 1) {
            setCurrentFeature(val);
          } else {
            console.log('check orgs!');
          }
          // if(Object.values(checkedOrgs).filter(el=> el))
        }}
        orgsList={orgsList}
      />
      <FeatureFlagModal
        isOpen={Boolean(currentFeature)}
        onClose={() => setCurrentFeature(null)}
        checkedOrgs={checkedOrgs}
        currentFeature={currentFeature}
        orgsDict={orgsDict}
        onConfirm={(action, orgs, email) => {
          doUpdates(action, orgs, email);
        }}
        // modalConfig={currentFeature}
      />
    </Box>
  );
}

function Table({
  data,
  columns,
  checkedOrgs,

  modalSetter,
}) {
  const [sorting, setSorting] = useState([]);
  // const fieldsWithSorting = useMemo(() => ['idsd'], []);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    enableSortingRemoval: false,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <TableContainer
      sx={{
        minHeight: '50vh',
      }}
    >
      <MuiTable>
        <TableHead>
          {table?.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} style={{ position: 'sticky' }}>
              {headerGroup.headers?.map((header) => {
                return (
                  <TitleTableCell
                    key={header.id}
                    colSpan={header.colSpan}
                    sx={{ borderBottom: 'none' }}
                  >
                    {header.isPlaceholder ? null : (
                      <Box
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'flex-start',
                          cursor: 'pointer',
                          borderBottom: 'none',
                        }}
                        // onClick={() => header.column.getToggleSortingHandler()}
                        onClick={() => modalSetter(header.id)}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {/* {fieldsWithSorting.includes(header.id) && (
                          <img src="/images/sort-icon.svg" />
                        )} */}
                      </Box>
                    )}
                  </TitleTableCell>
                );
              })}
            </tr>
          ))}
        </TableHead>
        <TableBody>
          {table?.getRowModel().rows.map((row) => {
            const { id } = row.original;
            return (
              <UsersTableRow key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <UsersTableCell
                      key={cell.id}
                      style={
                        checkedOrgs[id] ? { backgroundColor: '#c1d0e6' } : null
                      }
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </UsersTableCell>
                  );
                })}
              </UsersTableRow>
            );
          })}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}

const FeatureFlagModal = ({
  isOpen,
  onClose,
  onConfirm,
  checkedOrgs,
  currentFeature,
  orgsDict,
}) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const [textValue, setTextValue] = useState(null);
  // const []
  const [updateOrgs, setUpdateOrgs] = useState([]);
  const [emailsList, setEmailsList] = useState([]);

  const [confirm, setConfirm] = useState(false);
  const [action, setAction] = useState(null);

  const handleEmailTextChange = (e) => {
    const val = e.target.value;
    const lastChar = val[val.length - 1];
    if (lastChar === ',' || lastChar === ' ' || e.key === 'Enter') {
      const newEmail = val.slice(0, val.length - 1);
      if (emailRegex.test(newEmail)) {
        setEmailsList((p) => [...p, val.slice(0, val.length - 1)]);
        setTextValue('');
      } else {
        setTextValue('INVALID EMAIL ADDRESS');
        setTimeout(() => {
          setTextValue(newEmail);
        }, 800);
      }
    } else {
      setTextValue(val);
    }
  };

  // useEffect(() => {
  //   console.log(emailsList);
  // }, [emailsList]);
  useEffect(() => {
    const updateOrgsTemp = Object.keys(checkedOrgs).filter(
      (el) => checkedOrgs[el]
    );
    setUpdateOrgs(updateOrgsTemp);
    if (updateOrgsTemp.length === 1) {
      const [firstOrg] = updateOrgsTemp;
      const valueTemp =
        orgsDict[firstOrg]?.featureFlags[currentFeature] || 'disabled';

      if (valueTemp[0] !== 'everyone' && valueTemp !== 'disabled') {
        setEmailsList(valueTemp);
      }
    }
  }, [currentFeature, checkedOrgs]);

  return (
    <Modal open={isOpen}>
      <Box
        onClick={() => {
          setConfirm(false);
          setEmailsList([]);
          onClose();
        }}
        style={{
          height: '100%',
          width: '100%',
          zIndex: 1,
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          onClick={(e) => e.stopPropagation()}
          style={{
            backgroundColor: 'white',
            overflow: 'hidden',
            minWidth: '40vw',
            maxWidth: '60vw',
            borderRadius: 8,
          }}
        >
          <Box style={{ backgroundColor: 'lightblue', padding: '15px 25px' }}>
            <Text
              style={{
                fontWeight: 600,
                fontSize: 24,
                color: '#FF6A00',
              }}
            >
              {currentFeature} feature flag
            </Text>
            <Text
              style={{
                fontWeight: 500,
                fontSize: 14,
                color: '#FF6A00',
              }}
            >
              {`Orgs: ${updateOrgs.join(', ')}`}
            </Text>
          </Box>
          <Box
            style={{
              padding: '15px 25px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {confirm ? (
              <>
                <Text
                  onClick={() => setConfirm(false)}
                  style={{
                    fontWeight: 'bold',
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',
                  }}
                >
                  {action === 'email'
                    ? `Enable ${currentFeature} for ${emailsList.join(
                        ', '
                      )} of ${updateOrgs[0]}`
                    : `${
                        action[0].toUpperCase() + action.slice(1)
                      } ${currentFeature} for all ${updateOrgs.join(
                        ', '
                      )} users?`}
                </Text>
                <Button
                  style={{ margin: '15px 0' }}
                  onClick={() => {
                    onConfirm(action, [...updateOrgs], emailsList);
                    setConfirm(false);
                    setEmailsList([]);
                  }}
                >
                  Confirm
                </Button>
                <Button onClick={() => setConfirm(false)}>Cancel</Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => {
                    setAction('disable');
                    setConfirm(true);
                  }}
                  style={{ marginBottom: 5 }}
                >
                  Disable all users
                </Button>
                <Button
                  onClick={() => {
                    setAction('enable');
                    setConfirm(true);
                  }}
                >
                  Enable all users
                </Button>

                <Text
                  style={{ fontWeight: 600, fontSize: 18, marginTop: 5 }}
                  onClick={() => console.log(emailsList)}
                >
                  {`Enable Selected Users`}
                </Text>
                {emailsList.length > 0 && (
                  <Box
                    style={{
                      padding: '5px 0px',
                      display: 'flex',
                      gap: 5,
                      flexWrap: 'wrap',
                    }}
                  >
                    {emailsList.map((email, i) => (
                      <Box
                        key={i}
                        style={{
                          backgroundColor: 'lightblue',
                          padding: '4px 8px',
                          borderRadius: 4,
                          display: 'inline-block',
                        }}
                        onClick={() => {
                          console.log('clicked');
                          const temp = [...emailsList];
                          const index = temp.indexOf(email);
                          temp.splice(index, 1);
                          setEmailsList(temp);
                        }}
                      >
                        <Text style={{ color: '#FF6A00' }}>{email}</Text>
                      </Box>
                    ))}
                  </Box>
                )}
                <Text style={{ fontWeight: 500, fontSize: 12, marginTop: 3 }}>
                  {
                    'Enter user email separated by space, or enter. click an email address to remove from list.'
                  }
                </Text>
                {/* <Text style={{ fontWeight: 500, fontSize: 12, marginTop: 3 }}>
                  {'click an email address to remove from list'}
                </Text> */}
                <Text style={{ fontWeight: 500, fontSize: 12, marginTop: 3 }}>
                  {'to remove all emails please select Disable all users'}
                </Text>
                <TextField
                  disabled={updateOrgs.length > 1}
                  value={textValue || ''}
                  onChange={handleEmailTextChange}
                  onKeyDown={(e) =>
                    e.key === 'Enter' ? handleEmailTextChange(e) : null
                  }
                  style={{
                    marginBottom: 5,
                    color:
                      textValue === 'INVALID EMAIL ADDRESS' ? 'red' : 'black',
                  }}
                  placeholder={
                    updateOrgs.length > 1
                      ? 'available for a single org at a time'
                      : 'enter user emails'
                  }
                />
                <Button
                  onClick={() => {
                    if (!emailsList.length) return;
                    setAction('email');
                    setConfirm(true);
                  }}
                >
                  Save
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
