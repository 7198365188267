import React, { useState, useMemo, useEffect } from 'react';
import { Scheduler } from '@paiv/react-scheduler';
import {
  Box,
  Typography,
  Avatar,
  CircularProgress,
  Popover,
  Alert,
  Snackbar,
} from '@mui/material';
import { ProcessedEvent } from '@paiv/react-scheduler/types';
import Fuse from 'fuse.js';
import { API } from 'aws-amplify';
import * as mutations from '../../../../graphql/mutations';
import { useTeamsQuery } from './useTeamsQuery';
import { useUsersQuery, Employee } from './useUsersQuery';
import { CalendarEvent } from './types';
import { fetchAppointmentGrid } from './fetchAppointmentGrid';
import { DateDisplay, DateHeader, DateSelector } from './StyledDateComponents';
import { format, addMinutes, isValid, isSameDay } from 'date-fns';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DateRangePicker } from '../../../components/DateRangePicker/DateRangePicker';
import { SchedulingSidebar } from './SchedulingSidebar';

const formatTime = (date: Date): string => {
  return date
    .toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    })
    .toLowerCase();
};

// Predefined color palette for good contrast and readability
const COLOR_PALETTE = [
  '#FFD6CC', // Soft Red
  '#CCE5FF', // Soft Blue
  '#D6F5D6', // Soft Green
  '#FFF0CC', // Soft Yellow
  '#E5CCFF', // Soft Purple
  '#FFE5CC', // Soft Orange
  '#CCF2FF', // Light Blue
  '#FFCCF2', // Soft Pink
  '#E6FFCC', // Lime Green
  '#F2CCFF', // Light Purple
];

interface AppointmentRequest {
  appointmentDate: string;
  appointmentStartTime: string;
  appointmentEndTime: string;
  notes: string;
  closerID: string;
  action: string;
  actionTimestamp: string;
  appointmentID?: string;
}

// Add these utility functions
const roundToNearest30 = (date: Date): Date => {
  const minutes = date.getMinutes();
  const adjustment = minutes % 30;
  let toAdd = 0;

  if (adjustment === 0) {
    // If exactly on the hour or half-hour, no adjustment needed
    toAdd = 0;
  } else if (adjustment <= 15) {
    // If past the hour but <= 15 minutes, round down
    toAdd = -adjustment;
  } else {
    // If > 15 minutes past the hour or half-hour, round up
    toAdd = 30 - adjustment;
  }

  return addMinutes(date, toAdd);
};

const getLocalTimeRounded = (gmtDate: Date): string => {
  const date = new Date(gmtDate);
  const roundedDate = roundToNearest30(date);
  const hours = roundedDate.getHours().toString().padStart(2, '0');
  const minutes = roundedDate.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

const getLocalDate = (gmtDate: Date): string => {
  const date = new Date(gmtDate);
  return format(date, 'yyyy-MM-dd');
};

const newLocalToISODateFormat = (dateStr?: Date | string): string => {
  if (!dateStr || dateStr === 'undefined' || dateStr === '') {
    return '';
  }

  try {
    const date = typeof dateStr === 'string' ? new Date(dateStr) : dateStr;

    if (!isValid(date)) {
      return '';
    }

    return date.toISOString();
  } catch (error) {
    console.error('Error formatting date:', error);
    return '';
  }
};

interface TeamWithClosers {
  id: string;
  name: string;
  isSelected: boolean;
  closers: Employee[];
  image?: string;
}

export const SchedulingScreenView: React.FC = () => {
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<'people' | 'team'>('people');
  const [searchQuery, setSearchQuery] = useState('');
  const [teamMembers, setTeamMembers] = useState<Employee[]>([]);
  const [filteredEmployees, setFilteredEmployees] = useState<Employee[]>([]);
  const [filteredTeamMembers, setFilteredTeamMembers] = useState<Employee[]>(
    []
  );
  console.log('!!filteredTeamMembers', filteredTeamMembers);
  const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>([]);
  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [selectedDates, setSelectedDates] = useState<Date[]>([
    new Date(),
    new Date(new Date().setDate(new Date().getDate() + 1)),
  ]);
  const [colorAssignments, setColorAssignments] = useState<
    Record<string, string>
  >({});

  const { data: teams = [], isLoading: isTeamsLoading } = useTeamsQuery();
  const { data: employees = [], isLoading: isUsersLoading } = useUsersQuery();

  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
  const [teamsWithClosers, setTeamsWithClosers] = useState<TeamWithClosers[]>(
    []
  );
  const maxTeamMembers = 5;

  // Fuse.js options for search
  const options = {
    keys: ['name', 'role'],
    includeScore: true,
    threshold: 0.2,
  };

  const fuse = new Fuse(
    activeTab === 'people' ? employees : teamMembers,
    options
  );

  useEffect(() => {
    if (activeTab === 'team' && teams.length > 0) {
      const allMembers = teams.reduce((acc, team) => {
        team.users.items
          .filter(
            (member) =>
              member.isActive &&
              member.status !== 'removed' &&
              member.user &&
              member.user.status !== 'deleted'
          )
          .forEach((member) => {
            if (member.user?.id) {
              const user = employees.find((emp) => emp.id === member.user.id);
              if (user) {
                acc.add(user);
              }
            }
          });
        return acc;
      }, new Set<Employee>());

      setTeamMembers(Array.from(allMembers));
    }
  }, [activeTab, teams, employees]);

  // Handle search
  useEffect(() => {
    if (searchQuery) {
      const results = fuse.search(searchQuery).map(({ item }) => item);
      if (activeTab === 'people') {
        setFilteredEmployees(results);
      } else {
        setFilteredTeamMembers(results);
      }
    } else {
      setFilteredEmployees(employees);
      setFilteredTeamMembers(teamMembers);
    }
  }, [searchQuery, activeTab, employees, teamMembers]);

  // Updated resources structure
  const resources = employees.map((emp) => ({
    admin_id: emp.id,
    title: emp.name,
    mobile: emp.role,
    avatar: emp.avatar,
    color:
      emp.role === 'Tech'
        ? '#e8f3e3'
        : emp.role === 'Sales'
        ? '#fff3d9'
        : '#f3e8f3', // Support gets a different color
  }));

  // Updated event renderer with better styling
  const eventRenderer = (props: any) => {
    const { event, onClick, ...restProps } = props;
    const assignedColor = colorAssignments[event.admin_id] || '#e8f3e3';
    const displayFields = event.extendedProps?.displayFields || {};

    // Get the assigned user's info from resources
    const assignedUser = resources.find((r) => r.admin_id === event.admin_id);

    // Safely handle date conversion
    const getTimeString = (start: Date, end: Date) => {
      try {
        return `${formatTime(start)} - ${formatTime(end)}`;
      } catch (error) {
        return 'Invalid time';
      }
    };

    const timeString = getTimeString(event.start, event.end);

    return (
      <Box
        {...restProps}
        onClick={onClick}
        sx={{
          cursor: 'pointer',
          p: 1.5,
          height: '100%',
          bgcolor: assignedColor,
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
          gap: 0.5,
          border: '1px solid',
          borderColor: 'rgba(0,0,0,0.05)',
          '&:hover': {
            filter: 'brightness(0.97)',
          },
          '&:active': {
            cursor: 'grabbing',
            opacity: 0.8,
            transform: 'scale(0.98)',
          },
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 500,
              color: '#2c2c2c',
              fontSize: '0.875rem',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              flex: 1,
            }}
          >
            {displayFields.customerName || event.title}
          </Typography>
          {assignedUser?.avatar && (
            <Avatar
              src={assignedUser.avatar}
              alt={assignedUser.title}
              sx={{
                width: 20,
                height: 20,
                fontSize: '0.75rem',
                border: '1px solid',
                borderColor: 'rgba(0,0,0,0.1)',
                ml: 1,
                flexShrink: 0,
              }}
            />
          )}
        </Box>
        <Typography
          variant="caption"
          sx={{
            color: '#666',
            fontSize: '0.75rem',
            lineHeight: 1.2,
          }}
        >
          {timeString}
        </Typography>
        {displayFields.fullAddress && (
          <Typography
            variant="caption"
            sx={{
              color: '#666',
              fontSize: '0.75rem',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              lineHeight: 1.2,
            }}
          >
            {displayFields.fullAddress}
          </Typography>
        )}
        {displayFields.notes && (
          <Typography
            variant="caption"
            sx={{
              color: '#666',
              fontSize: '0.75rem',
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              lineHeight: 1.2,
              fontStyle: 'italic',
            }}
          >
            {displayFields.notes}
          </Typography>
        )}
      </Box>
    );
  };

  // Memoize the filtered resources and events
  const filteredResources = useMemo(
    () =>
      resources.filter((resource) =>
        selectedEmployees.includes(resource.admin_id)
      ),
    [resources, selectedEmployees]
  );

  const filteredEvents = useMemo(
    () =>
      calendarEvents.filter((event) =>
        selectedEmployees.includes(event.admin_id)
      ),
    [calendarEvents, selectedEmployees]
  );

  // Add this to help debug state updates
  useEffect(() => {
    console.log('Selected Employees:', selectedEmployees);
    console.log('Filtered Resources:', filteredResources);
    console.log('Filtered Events:', filteredEvents);
  }, [selectedEmployees, filteredResources, filteredEvents]);

  const scheduleAppointment = async ({
    appointmentID,
    closerID,
    appointmentStartTime,
    appointmentEndTime,
    notes = '',
    onSuccess,
  }: {
    appointmentID?: string;
    closerID: string;
    appointmentStartTime: Date;
    appointmentEndTime: Date;
    notes?: string;
    onSuccess: (result: string | false) => void;
  }): Promise<void> => {
    console.debug('!!!scheduleAppointment: ', {
      appointmentID,
      closerID,
      appointmentStartTime,
      appointmentEndTime,
      notes,
    });

    try {
      const request: AppointmentRequest = {
        appointmentDate: getLocalDate(appointmentStartTime),
        appointmentStartTime: getLocalTimeRounded(appointmentStartTime),
        appointmentEndTime: getLocalTimeRounded(appointmentEndTime),
        notes,
        closerID,
        action: appointmentID ? 'rescheduled' : 'scheduled',
        actionTimestamp: newLocalToISODateFormat(appointmentStartTime),
      };

      if (appointmentID) {
        request.appointmentID = appointmentID;
      }

      console.log('🚀 ~ request:', request);

      await API.graphql({
        query: mutations.bookCalendarSlot,
        variables: {
          request: JSON.stringify(request),
        },
      });

      setSnackbar({
        open: true,
        message: appointmentID
          ? 'Appointment rescheduled'
          : 'Appointment scheduled',
        severity: 'success',
      });

      onSuccess('someid');
    } catch (err) {
      console.error('Error scheduling appointment:', err);
      setSnackbar({
        open: true,
        message: 'Error while trying to schedule an appointment',
        severity: 'error',
      });
      onSuccess(false);
    }
  };

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error' | 'warning';
  }>({
    open: false,
    message: '',
    severity: 'success',
  });

  // Add max members check
  const MAX_SELECTED_MEMBERS = 5;

  // Update handleEmployeeSelection to check for max members
  const handleEmployeeSelection = (employeeId: string) => {
    setSelectedEmployees((prev) => {
      const isSelected = prev.includes(employeeId);
      if (isSelected) {
        setColorAssignments((prevColors) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [employeeId]: removedColor, ...rest } = prevColors;
          return rest;
        });
        return prev.filter((id) => id !== employeeId);
      } else {
        // Check if we've reached the maximum
        if (prev.length >= MAX_SELECTED_MEMBERS) {
          setSnackbar({
            open: true,
            message: `You can only select up to ${MAX_SELECTED_MEMBERS} members`,
            severity: 'warning',
          });
          return prev;
        }
        // Assign new color when employee is selected
        const availableColors = COLOR_PALETTE.filter(
          (color) => !Object.values(colorAssignments).includes(color)
        );
        const newColor = availableColors[0] || COLOR_PALETTE[0];
        setColorAssignments((prev) => ({ ...prev, [employeeId]: newColor }));
        return [...prev, employeeId];
      }
    });
  };

  // Update team selection handler to auto-select first 5 closers
  const handleTeamSelection = (teamId: string) => {
    setSelectedTeams((prev) => {
      const isSelected = prev.includes(teamId);
      if (isSelected) {
        // Remove team and its members
        const teamClosers =
          teamsWithClosers.find((t) => t.id === teamId)?.closers || [];
        setSelectedEmployees((prev) =>
          prev.filter((id) => !teamClosers.some((closer) => closer.id === id))
        );
        return prev.filter((id) => id !== teamId);
      } else {
        // Auto-select first 5 closers
        const team = teamsWithClosers.find((t) => t.id === teamId);
        if (team) {
          const closersToAdd = team.closers
            .slice(0, MAX_SELECTED_MEMBERS)
            .map((closer) => closer.id);

          // Assign colors to new closers
          const newColorAssignments = { ...colorAssignments };
          closersToAdd.forEach((closerId, index) => {
            if (!newColorAssignments[closerId]) {
              newColorAssignments[closerId] =
                COLOR_PALETTE[index % COLOR_PALETTE.length];
            }
          });
          setColorAssignments(newColorAssignments);

          setSelectedEmployees(closersToAdd);
        }
        return [...prev, teamId];
      }
    });
  };

  // Update handleTeamMemberSelection to check for max members
  const handleTeamMemberSelection = (employeeId: string, teamId: string) => {
    const team = teamsWithClosers.find((t) => t.id === teamId);
    if (!team) return;

    setSelectedEmployees((prev) => {
      const isSelected = prev.includes(employeeId);
      if (isSelected) {
        setColorAssignments((prevColors) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [employeeId]: removedColor, ...rest } = prevColors;
          return rest;
        });
        return prev.filter((id) => id !== employeeId);
      } else {
        // Check if we've reached the maximum
        if (prev.length >= MAX_SELECTED_MEMBERS) {
          setSnackbar({
            open: true,
            message: `You can only select up to ${MAX_SELECTED_MEMBERS} members`,
            severity: 'warning',
          });
          return prev;
        }
        // Assign new color when employee is selected
        const availableColors = COLOR_PALETTE.filter(
          (color) => !Object.values(colorAssignments).includes(color)
        );
        const newColor = availableColors[0] || COLOR_PALETTE[0];
        setColorAssignments((prev) => ({ ...prev, [employeeId]: newColor }));
        return [...prev, employeeId];
      }
    });
  };

  // Update useEffect for teams with closers to include avatar
  useEffect(() => {
    if (teams.length > 0 && employees.length > 0) {
      const updatedTeams: TeamWithClosers[] = teams.map((team) => ({
        id: team.id,
        name: team.name,
        isSelected: selectedTeams.includes(team.id),
        closers: team.users.items
          .filter(
            (member) =>
              member.isActive &&
              member.status !== 'removed' &&
              member.user &&
              member.user.status !== 'deleted' &&
              member.user.functionalRoles?.includes('closer')
          )
          .map((member) => {
            if (!member.user?.id) return undefined;
            const employee = employees.find((emp) => emp.id === member.user.id);
            if (!employee) return undefined;
            return {
              ...employee,
              // Construct avatar URL from imageName and imageType if available
              avatar:
                member.user.imageName && member.user.imageType
                  ? `${member.user.imageName}.${member.user.imageType}`
                  : employee.avatar,
            };
          })
          .filter((user): user is Employee => user !== undefined)
          .slice(0, maxTeamMembers),
      }));

      setTeamsWithClosers(updatedTeams);
    }
  }, [teams, employees, selectedTeams, maxTeamMembers]);

  // Add snackbar close handler
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // Update handleEventDrop
  const handleEventDrop = async (
    _: React.DragEvent<HTMLButtonElement>,
    droppedOn: Date,
    updatedEvent: ProcessedEvent,
    originalEvent: ProcessedEvent & { event_id: string; dealId: string }
  ): Promise<ProcessedEvent | void> => {
    console.log('Drop Event Debug:', {
      originalEvent,
      updatedEvent,
      droppedOn,
      currentEvents: calendarEvents,
    });

    // Create a new event with all the necessary properties
    const newEvent: CalendarEvent = {
      ...originalEvent,
      start: updatedEvent.start,
      end: updatedEvent.end,
      admin_id: updatedEvent.admin_id as string,
      event_id: originalEvent.event_id,
      id: originalEvent.event_id,
      dealId: originalEvent.dealId,
      title: originalEvent.title,
      extendedProps: originalEvent.extendedProps,
    };

    const uniqueDatesSet = new Set<string>();
    uniqueDatesSet.add(
      new Date(originalEvent.start).toISOString().split('T')[0]
    );
    uniqueDatesSet.add(
      new Date(updatedEvent.start).toISOString().split('T')[0]
    );
    selectedDates.forEach((date) => {
      uniqueDatesSet.add(date.toISOString().split('T')[0]);
    });

    setCalendarEvents((prevEvents) =>
      prevEvents.map((event) =>
        event.id === originalEvent.event_id ? newEvent : event
      )
    );

    try {
      await scheduleAppointment({
        appointmentID: originalEvent.event_id,
        closerID: updatedEvent.admin_id as string,
        appointmentStartTime: updatedEvent.start,
        appointmentEndTime: updatedEvent.end,
        notes: originalEvent.extendedProps?.displayFields?.notes || '',
        onSuccess: async (result) => {
          if (!result) {
            // Revert the UI change if the API call fails
            setCalendarEvents((prevEvents) => {
              return prevEvents.map((event) => {
                if (event.id === originalEvent.event_id) {
                  return {
                    ...originalEvent,
                    event_id: originalEvent.event_id,
                    id: originalEvent.event_id,
                    dealId: originalEvent.dealId,
                  } as CalendarEvent;
                }
                return event;
              });
            });

            setSnackbar({
              open: true,
              message: 'Failed to update appointment',
              severity: 'error',
            });
          } else {
            // Success case - we already have the optimistic update
            setSnackbar({
              open: true,
              message: 'Appointment updated successfully',
              severity: 'success',
            });
          }
        },
      });
      return updatedEvent;
    } catch (error) {
      console.error('Error updating appointment:', error);

      // Revert the UI change on error
      setCalendarEvents((prevEvents) => {
        return prevEvents.map((event) => {
          if (event.id === originalEvent.event_id) {
            return {
              ...originalEvent,
              event_id: originalEvent.event_id,
              id: originalEvent.event_id,
              dealId: originalEvent.dealId,
            } as CalendarEvent;
          }
          return event;
        });
      });

      setSnackbar({
        open: true,
        message: 'Failed to update appointment',
        severity: 'error',
      });

      return originalEvent;
    }
  };

  // Add debug log for calendar events changes
  useEffect(() => {
    console.log('Calendar Events Updated:', {
      events: calendarEvents,
      filtered: filteredEvents,
    });
  }, [calendarEvents, filteredEvents]);

  useEffect(() => {
    let isMounted = true;
    console.log('!!selectedDates', selectedDates);

    const fetchData = async () => {
      if (selectedEmployees.length > 0) {
        try {
          setIsLoadingEvents(true);
          const events = await fetchAppointmentGrid(
            selectedDates,
            selectedEmployees
          );
          if (isMounted) {
            setCalendarEvents(events);
          }
        } catch (error) {
          console.error('Error fetching appointments:', error);
        } finally {
          if (isMounted) {
            setIsLoadingEvents(false);
          }
        }
      } else {
        // Clear events when no employees are selected
        setCalendarEvents([]);
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [selectedEmployees, selectedDates]);

  const handleDateClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation(); // Prevent event bubbling
    setAnchorEl(event.currentTarget);
  };

  const handleDateSelect = (date: Date) => {
    setSelectedDates((prev) => {
      // Create a new date at noon to avoid timezone issues
      const newDate = new Date(date);
      newDate.setHours(12, 0, 0, 0);

      // If we already have this date selected, remove it
      // But only if it's not the last date
      if (prev.some((d) => isSameDay(d, newDate))) {
        const filtered = prev.filter((d) => !isSameDay(d, newDate));
        // If we would remove the last date, keep today's date
        return filtered.length === 0 ? [new Date()] : filtered;
      }

      // If adding this date would exceed 3, replace the oldest one
      if (prev.length >= 3) {
        // Sort dates chronologically and take the last 2 plus the new date
        const sortedDates = [...prev].sort((a, b) => a.getTime() - b.getTime());
        return [...sortedDates.slice(-2), newDate].sort(
          (a, b) => a.getTime() - b.getTime()
        );
      }

      // Add the new date and sort them
      return [...prev, newDate].sort((a, b) => a.getTime() - b.getTime());
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderDateHeader = () => (
    <DateHeader
      style={{
        position: 'fixed',
        top: 80,
        left: 300,
        right: 0,
        backgroundColor: '#fff',
        zIndex: 1000,
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      }}
    >
      <DateSelector onClick={handleDateClick}>
        <DateDisplay>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 500, color: '#ff6a00' }}
          >
            Select Dates
          </Typography>
        </DateDisplay>
        <Box
          component="span"
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: '#ff6a00',
            '& svg': {
              fontSize: 18,
            },
          }}
        >
          <ArrowDropDownIcon />
        </Box>
      </DateSelector>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPopover-paper': {
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
            marginTop: '8px',
          },
        }}
      >
        <DateRangePicker
          selectedDates={selectedDates}
          onDateSelect={handleDateSelect}
          onClose={handleClose}
        />
      </Popover>
    </DateHeader>
  );

  return (
    <Box display="flex" height="100vh">
      <SchedulingSidebar
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        isLoading={isTeamsLoading || isUsersLoading}
        filteredEmployees={filteredEmployees}
        selectedEmployees={selectedEmployees}
        handleEmployeeSelection={handleEmployeeSelection}
        colorAssignments={colorAssignments}
        employees={employees}
        teamsWithClosers={teamsWithClosers}
        handleTeamSelection={handleTeamSelection}
        handleTeamMemberSelection={handleTeamMemberSelection}
      />
      <Box
        flex={1}
        sx={{
          position: 'relative',
          height: '100%',
          overflow: 'hidden',
          marginTop: '70px',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 10,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {renderDateHeader()}
          {isLoadingEvents ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flex={1}
            >
              <CircularProgress />
            </Box>
          ) : selectedEmployees.length === 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flex={1}
              flexDirection="column"
              gap={2}
            >
              <Typography variant="h6" color="text.secondary">
                Select team members to view their schedules
              </Typography>
              <Typography variant="body2" color="text.secondary">
                You can select up to {MAX_SELECTED_MEMBERS} members at a time
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                flex: 1,
                overflow: 'auto',
                pt: '70px',
                height: 'calc(100vh - 80px)',
              }}
            >
              <Scheduler
                events={calendarEvents}
                eventRenderer={eventRenderer}
                view="manyDays"
                selectedDate={selectedDates[0]}
                navigation={false}
                disableViewNavigator={true}
                onEventDrop={handleEventDrop}
                editable={true}
                draggable
                timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                manyDays={{
                  startHour: 8,
                  endHour: 18,
                  step: 60,
                  weekDays: [0, 1, 2, 3, 4, 5, 6],
                  weekStartOn: 0,
                  daysList: selectedDates.map(
                    (date) =>
                      new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate(),
                        0,
                        0,
                        0
                      )
                  ),
                }}
                resources={filteredResources}
                resourceFields={{
                  idField: 'admin_id',
                  textField: 'title',
                  avatarField: 'avatar',
                  colorField: 'color',
                }}
                height={750}
                manyDaysHeaderComponent={({ user, date }) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      pt: 1.2,
                      position: 'relative',
                      height: '100%',
                      background: '#fff',
                      zIndex: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        left: 0,
                        right: 0,
                        textAlign: 'center',
                        pb: 0.3,
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 500, mt: 1 }}
                      >
                        {date.getDate()}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ color: 'text.secondary' }}
                      >
                        {date.toLocaleDateString('en-US', {
                          weekday: 'short',
                        })}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        mt: 1,
                        maxWidth: '100%',
                        overflow: 'hidden',
                      }}
                    >
                      <Avatar
                        src={user.avatar}
                        alt={user.title}
                        sx={{
                          width: 24,
                          height: 24,
                          flexShrink: 0,
                        }}
                      />
                      <Box sx={{ ml: 1, overflow: 'hidden' }}>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontWeight: 'bold',
                            mb: 0,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            lineHeight: 1.2,
                          }}
                        >
                          {user.title}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            color: 'text.secondary',
                            display: 'block',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            lineHeight: 1.2,
                          }}
                        >
                          {user.mobile}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
