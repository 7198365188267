import { API, graphqlOperation } from 'aws-amplify';
import { useQuery } from '@tanstack/react-query';
import getUserAvatar from '../../../common/utils/getUserAvatar';

export interface Employee {
  id: string;
  name: string;
  role: string;
  avatar: string;
  status: string;
  imageName: string;
  imageType: string;
  functionalRoles?: string[];
}

const fetchUsers = async () => {
  const myQuery = `
    query MyQuery($orgID: ID!) {
      searchUsers(
        filter: {
          orgID: { eq: $orgID }
          status: { ne: "deleted" }
        }
        limit: 750
        sort: { direction: asc, field: name }
      ) {
        items {
          id
          name
          status
          imageName
          imageType
          functionalRoles
        }
      }
    }
  `;

  const userData: any = await API.graphql(
    graphqlOperation(myQuery, {
      orgID: (global as any).me.orgID,
    })
  );

  const users = userData.data?.searchUsers?.items || [];
  const usersList: Employee[] = [];

  for (const user of users) {
    if (user.status === 'deleted') continue;

    const avatarFile = await getUserAvatar(
      user.imageName,
      user.imageType,
      true
    );
    const functionalRole = user.functionalRoles
      ? user.functionalRoles
          .filter((role: string) => role !== '')
          .map((role: string) => role[0].toUpperCase() + role.slice(1))
          .join(' & ')
      : '';

    usersList.push({
      id: user.id,
      name: user.name,
      role: functionalRole || 'None',
      avatar: avatarFile,
      status: user.status,
      imageName: user.imageName,
      imageType: user.imageType,
      functionalRoles: user.functionalRoles,
    });
  }

  return usersList;
};

export const useUsersQuery = () => {
  return useQuery({
    queryKey: ['users'],
    queryFn: fetchUsers,
  });
};
